import {
  AllQuery,
  BrandPost,
  BrandProps,
  UpdateBrandProps,
} from "../interfaces/interfaces";
import { apiSlice } from "./apiSlice";

export const brandApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBrands: builder.query<BrandProps[] | BrandProps, AllQuery>({
      query: ({ operation, filterId, filterCategory }) =>
        `/brands?operation=${operation}${filterId ? `&id=${filterId}` : ""}${filterCategory ? `&category=${filterCategory}` : ""}`,
      providesTags: ["Brands"],
    }),
    createBrand: builder.mutation<BrandProps, BrandPost>({
      query: (body) => ({
        url: `/brands/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Brands"],
    }),
    updateBrand: builder.mutation<BrandProps, UpdateBrandProps>({
      query: ({ id, ...body }) => ({
        url: `/brands/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Brands"],
    }),
    logicStatusBrand: builder.mutation<string, number>({
      query: (id) => ({
        url: `/brands/des-active/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Brands"],
    }),
    deleteBrand: builder.mutation<string, number>({
      query: (id) => ({
        url: `/brands/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Brands"],
    }),
  }),
});

export const {
  useCreateBrandMutation,
  useGetBrandsQuery,
  useUpdateBrandMutation,
  useLogicStatusBrandMutation,
  useDeleteBrandMutation,
} = brandApiSlice;

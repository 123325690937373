import { useEffect, useState } from "react";
import "../../../../styles/admin/options.scss";
import Spinner from "../../../spinner/Spinner";
import {
  SearchProps,
  UpdateSubcategoryProps,
} from "../../../../interfaces/interfaces";
import { useGetCategoriesQuery } from "../../../../features/categoryApiSlice";
import { useError } from "../../../../hooks/useError";
import {
  useGetSubcategoriesQuery,
  useUpdateSubcategoryMutation,
} from "../../../../features/subcategoryApiSlice";

const EditSubcategory = () => {
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const { data: categories } = useGetCategoriesQuery({
    operation: "active",
  });
  const { data: subcategories, isLoading } = useGetSubcategoriesQuery({
    operation: "active",
    filterSubcategory: selected,
  });
  const [search, setSearch] = useState<SearchProps>({
    active: false,
    content: "",
  });
  const [updateSubcategory, response] = useUpdateSubcategoryMutation();
  const { isSuccess, error, isError } = response;
  const { errorMsg } = useError(error, isError);
  const [editSubcategory, setEditSubcategory] =
    useState<UpdateSubcategoryProps>({
      id: 0,
      name: "",
      categoryId: 0,
      isActive: true,
    });

  useEffect(() => {
    if (!Array.isArray(subcategories) && subcategories) {
      const { updatedAt, createdAt, ...formated } = subcategories;
      setEditSubcategory(formated);
      const selector = document.getElementById(
        "categorySel"
      ) as HTMLSelectElement;
      selector.value = `${subcategories?.categoryId}`;
    }
  }, [subcategories]);
  //HANDLES START
  const handleUpdate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    updateSubcategory(editSubcategory);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      active: e.target.value !== "" ? true : false,
      content: e.target.value,
    });
  };
  const handleSelectItem = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSearch({
      active: false,
      content: "",
    });
    setSelected(e.currentTarget.value);
  };
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    if (e.target.value === "default") {
      setEditSubcategory({
        ...editSubcategory,
        [e.target.name]: 0,
      });
    } else {
      setEditSubcategory({
        ...editSubcategory,
        [e.target.name]: Number(e.target.value),
      });
    }
  };
  const handleForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setEditSubcategory({
      ...editSubcategory,
      [e.target.name]: e.target.value,
    });
  };
  //HANDLES END

  return (
    <div className="option-container edit-category">
      {Array.isArray(subcategories) && (
        <>
          <div className="search-container">
            <h5 className="search-name">Seleccionar una subcategoría:</h5>
            <div className="search-bar">
              <input
                type="text"
                onChange={handleChange}
                placeholder="Buscar una subcategoría"
                value={search.content}
              ></input>
            </div>
            <div className="search-results" hidden={!search.active}>
              {search.content !== "" &&
                subcategories
                  .filter((subcategory) =>
                    subcategory?.name
                      ?.toLowerCase()
                      .includes(search.content.toLowerCase())
                  )
                  .map((subcategory) => {
                    return (
                      <button
                        className="result"
                        key={subcategory.id}
                        onClick={handleSelectItem}
                        value={subcategory.name}
                      >
                        {subcategory.name}
                      </button>
                    );
                  })}
            </div>
          </div>
          {subcategories.map((subcategory) => {
            return (
              <button
                className="result"
                key={subcategory.id}
                onClick={handleSelectItem}
                value={subcategory.name}
              >
                {subcategory.name}
              </button>
            );
          })}
        </>
      )}
      {isLoading && (
        <div className="spinner-row">
          <Spinner size="2rem" opacity={1} />
          <p>Cargando información de la/s subcategoría/s...</p>
        </div>
      )}
      {selected !== undefined && !Array.isArray(subcategories) && (
        <>
          <div className="selected-container">
            <p className="selected-text">
              Articulo seleccionado: <span>{subcategories?.name}</span>
            </p>
            <button
              onClick={handleSelectItem}
              value={undefined}
              className="change-button"
            >
              Cambiar
            </button>
          </div>
          <form className="create-form">
            <div className="input-container-small">
              <label htmlFor="name">Nombre</label>
              <input
                name="name"
                type="text"
                value={editSubcategory.name}
                className="input-small"
                onChange={handleForm}
              />
            </div>
            <div className="select-container-large">
              <label htmlFor="categoryId">Categoría</label>
              <select
                name="categoryId"
                id="categorySel"
                defaultValue={"default"}
                className="select-small"
                onChange={handleSelect}
              >
                <option value={"default"} disabled>
                  Categoría...
                </option>
                {Array.isArray(categories) &&
                  categories?.map((category) => {
                    return (
                      <option
                        id={category.name}
                        value={category.id}
                        key={category.id}
                      >
                        {category.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="save-button-container">
              {isLoading && <Spinner size="2rem" opacity={1} />}
              <button
                disabled={editSubcategory.name === ""}
                onClick={handleUpdate}
                className="save-button"
              >
                {isLoading ? "Enviando..." : "Guardar"}
              </button>
            </div>
          </form>
          {(isError || isSuccess || isError) && (
            <ul className={isError ? "errors-container" : "success-container"}>
              {isError && <li>{errorMsg}</li>}
              {isSuccess && (
                <li>La subcategoría fue actualizada correctamente</li>
              )}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

export default EditSubcategory;

import { useEffect, useState } from "react";
import { isErrorWithMessage, isFetchBaseQueryError } from "../utils";

export const useError = (error: unknown, isError: boolean) => {
  const [errorMsg, setErrorMessage] = useState("");
  const fetchError = isFetchBaseQueryError(error);
  const messageError = isErrorWithMessage(error);

  useEffect(() => {
    if (!isError) {
      setErrorMessage("");
    } else {
      if (fetchError) {
        const errMsg = "error" in error ? error.error : String(error.data);
        setErrorMessage(errMsg);
      }
      if (messageError) {
        setErrorMessage(error.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return { errorMsg };
};

import React, { useState } from "react";
import Filters from "./Filters";
import MainCard from "../../components/main/content/MainCard";
import "../../styles/main/list.scss";
import { useGetProductsListQuery } from "../../features/listApiSlice";
import { useError } from "../../hooks/useError";
import Pagination from "./Pagination";
import Spinner from "../../components/spinner/Spinner";

export interface FiltersNames {
  brand: string;
  category: string;
  subcategory: string;
}

const ListLayout = () => {
  //FILTERS | START |
  const [filterRecommended, setRecommendedFilter] = useState<
    string | undefined
  >(undefined);
  const [filterBrand, setBrandFilter] = useState<string | undefined>(undefined);
  const [filterCategory, setCategoryFilter] = useState<string | undefined>(
    undefined
  );
  const [filterSubcategory, setSubcategoryFilter] = useState<
    string | undefined
  >(undefined);
  const [filterStock, setStockFilter] = useState<string | undefined>(undefined);
  //FILTERS | END |
  const [currentFilters, setCurrentFilters] = useState<FiltersNames>({
    brand: "",
    category: "",
    subcategory: "",
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 8,
  });
  const [orderBy, setOrderBy] = useState<string>("ASC");

  const { data, isLoading, isError, error } = useGetProductsListQuery({
    operation: "active",
    filterRecommended,
    filterBrand,
    filterCategory,
    filterSubcategory,
    filterStock,
    pagination,
    orderBy,
  });

  const { errorMsg } = useError(error, isError);

  const changePrevNextPage = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.currentTarget.id === "prev") {
      if (data?.currentPage === 1) return;
      setPagination({
        ...pagination,
        currentPage: pagination.currentPage - 1,
      });
      window.scrollTo(0, 0);
    }
    if (e.currentTarget.id === "next") {
      if (data?.currentPage === data?.totalPages) return;
      setPagination({
        ...pagination,
        currentPage: pagination.currentPage + 1,
      });
      window.scrollTo(0, 0);
    }
  };

  const changePage = (e: React.MouseEvent<HTMLDivElement>, page: number) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setPagination({
      ...pagination,
      currentPage: page,
    });
  };

  const handleSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    console.log(e.currentTarget.value);
    setPagination({ ...pagination, pageSize: parseInt(e.currentTarget.value) });
  };

  const handleOrder = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setOrderBy(e.currentTarget.value);
  };

  const pages = Array(data?.totalPages).fill("page", 0, data?.totalPages);

  return (
    <div className="mainLayout">
      <div className="breadCrumb">
        Productos
        {filterBrand && <span>- {currentFilters.brand}</span>}
        {filterCategory && <span>- {currentFilters.category}</span>}
        {filterSubcategory && <span>- {currentFilters.subcategory}</span>}
        {filterRecommended && filterRecommended === "true" && (
          <span>- Recomendados</span>
        )}
        {filterRecommended && filterRecommended === "false" && (
          <span>- No Recomendados</span>
        )}
        {filterStock && filterStock === "true" && <span>- Con stock</span>}
        {filterStock && filterStock === "false" && <span>- Sin stock</span>}
      </div>
      <div className="listLayout">
        <div className="filterContainer">
          <Filters
            setBrand={setBrandFilter}
            setCategory={setCategoryFilter}
            setSubcategory={setSubcategoryFilter}
            setFilters={setCurrentFilters}
            setRecommended={setRecommendedFilter}
            setStock={setStockFilter}
          />
        </div>
        <div className="rightContainer">
          <div className="optionsContainer">
            {!isLoading && data?.items.length !== 0 && (
              <span>Resultados: {data?.totalProducts} productos</span>
            )}
            <div className="optionRight">
              <div className="optionDiv">
                <label>Mostrar:</label>
                <select name="pageSize" id="pageSize" onChange={handleSize}>
                  <option value="8">8</option>
                  <option value="16">16</option>
                  <option value="32">32</option>
                </select>
              </div>
              <div className="optionDiv">
                <label>Ordenar:</label>
                <select name="order" id="pageSize" onChange={handleOrder}>
                  <option value="ASC">A-Z</option>
                  <option value="DESC">Z-A</option>
                </select>
              </div>
            </div>
          </div>
          {!isLoading && data?.items.length !== 0 && (
            <Pagination
              currentPage={data?.currentPage}
              pages={pages}
              totalPages={data?.totalPages}
              changePage={changePage}
              changePrevNextPage={changePrevNextPage}
            />
          )}
          <div
            className={`resultContainer${
              data?.items.length === 0 ? " empty" : ""
            }`}
          >
            {isLoading && <Spinner size="3rem" opacity={1} />}
            {!isLoading &&
              data?.items.map((product) => {
                return <MainCard {...product} key={product.id} />;
              })}
            {isError && errorMsg}
            {!isLoading && data?.items.length === 0 && (
              <div>
                <p>
                  No se encontraron productos con los requerimientos
                  solicitados.
                </p>
                <p>Por favor, elimine los filtros y utilice otra combinación</p>
              </div>
            )}
          </div>
          {!isLoading && data?.items.length !== 0 && (
            <Pagination
              currentPage={data?.currentPage}
              pages={pages}
              totalPages={data?.totalPages}
              changePage={changePage}
              changePrevNextPage={changePrevNextPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default ListLayout;

import { useState } from "react";
import "../../../../styles/admin/options.scss";
import { useError } from "../../../../hooks/useError";
import RecoverAdminCard from "../../card/RecoverAdminCard";
import {
  useDeleteSubcategoryMutation,
  useGetSubcategoriesQuery,
  useLogicStatusSubcategoryMutation,
} from "../../../../features/subcategoryApiSlice";
import { SearchProps } from "../../../../interfaces/interfaces";
import Spinner from "../../../spinner/Spinner";

const TrashSubcategory = () => {
  const [search, setSearch] = useState<SearchProps>({
    active: false,
    content: "",
  });
  const {
    data: subcategories,
    isLoading,
    isError,
    error,
  } = useGetSubcategoriesQuery({
    operation: "inactive",
  });
  const { errorMsg } = useError(error, isError);

  const [logicStatusSubcategory, response] =
    useLogicStatusSubcategoryMutation();
  const { isSuccess, error: delError, isError: delIsError } = response;
  const { errorMsg: deleteError } = useError(delError, delIsError);

  const [deleteSubcategory, status] = useDeleteSubcategoryMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      active: e.target.value !== "" ? true : false,
      content: e.target.value,
    });
  };

  return (
    <div className="option-container delete subcategory">
      {isLoading && <Spinner size="2rem" opacity={1} />}
      {!isError && !isLoading && (
        <div className="search-container">
          <div className="search-bar">
            <input
              type="text"
              onChange={handleChange}
              placeholder="Buscar una subcategoría"
              value={search.content}
            ></input>
          </div>
        </div>
      )}
      {(isError || delIsError) && (
        <ul className="errors-container">
          {isError && <li>{errorMsg}</li>}
          {delIsError && <li>{deleteError}</li>}
        </ul>
      )}
      {isSuccess && (
        <ul className="success-container">
          <li>{response.data}</li>
        </ul>
      )}
      {status.isSuccess && (
        <ul className="success-container">
          <li>
            La subcategoría ha sido eliminada completamente de la base de datos
            junto a los productos que tenía asociados.
          </li>
        </ul>
      )}
      {!isError &&
        Array.isArray(subcategories) &&
        (search.active
          ? subcategories
              .filter((subcategory) =>
                subcategory?.name
                  ?.toLowerCase()
                  .includes(search.content.toLowerCase())
              )
              .map((subcategory) => {
                return (
                  <RecoverAdminCard
                    recoverFn={logicStatusSubcategory}
                    deleteFn={deleteSubcategory}
                    element={subcategory}
                  />
                );
              })
          : subcategories.map((subcategory) => {
              return (
                <RecoverAdminCard
                  recoverFn={logicStatusSubcategory}
                  deleteFn={deleteSubcategory}
                  element={subcategory}
                />
              );
            }))}
    </div>
  );
};

export default TrashSubcategory;

import React, { useEffect, useState } from "react";
import "../../../styles/admin/headerAdmin.scss";
import logo from "../../../img/DinamoLogo.png";
import name from "../../../img/DinamoName.png";
import { adminButtons } from "../../../private/private";
import ButtonAdmin from "./ButtonAdmin";

function HeaderAdmin({ selected }: { selected: number }) {
  const [active, setActive] = useState(selected);

  useEffect(() => {
    const button = document.querySelector(".menu-link.active");
    const buttonActive = document.querySelector(`#menu-link${active}`);
    if (button) {
      button.className = button.className.replace(" active", "");
    }
    if (buttonActive) {
      buttonActive.className += " active";
    }
  }, [active]);

  return (
    <header id="headerAdmin">
      <section className="logo-container">
        <img src={logo} className="logo" alt="Logo Dinamo" />
        <img src={name} className="name" alt="Logo Dinamo Nombre" />
      </section>
      <section className="menu-container">
        {adminButtons.map(({ id, name }) => {
          return (
            <ButtonAdmin
              key={id}
              id={id}
              path={name}
              first={id === 1}
              last={id === adminButtons.length}
              activate={setActive}
            />
          );
        })}
      </section>
    </header>
  );
}

export default HeaderAdmin;

import "../../../../styles/admin/options.scss";
import { CreateProductProps } from "../../../../interfaces/interfaces";
import { useEffect, useState } from "react";
import imgDefault from "../../../../img/imgDefault.jpeg";
import { useGetSubcategoriesQuery } from "../../../../features/subcategoryApiSlice";
import { useGetCategoriesQuery } from "../../../../features/categoryApiSlice";
import { useGetBrandsQuery } from "../../../../features/brandApiSlice";
import { useCreateProductMutation } from "../../../../features/productApiSlice";
import { useError } from "../../../../hooks/useError";
import Spinner from "../../../spinner/Spinner";
import { DRIVE_URL } from "../../../../private/private";

const CreateProduct = () => {
  const { data: brands, isError: brandsError } = useGetBrandsQuery({
    operation: "active",
  });
  const { data: categories, isError: categoryError } = useGetCategoriesQuery({
    operation: "active",
  });
  const [newProduct, setNewProduct] = useState<CreateProductProps>({
    code: "",
    name: "",
    images: [],
    description: "",
    recommended: false,
    stock: true,
    brandId: 0,
    categoryId: 0,
    subcategoryId: 0,
  });
  const [imagePH, setImagePH] = useState<string>(imgDefault);
  const { data: subcategories, isError: subcategoryError } =
    useGetSubcategoriesQuery({
      operation: "active",
      filterId:
        newProduct.categoryId !== 0 ? String(newProduct.categoryId) : undefined,
    });
  const [createProduct, response] = useCreateProductMutation();
  const { isError, error, isSuccess, isLoading } = response;
  const { errorMsg } = useError(error, isError);

  const handleCreate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    createProduct(newProduct);
  };

  const handleForm = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    setNewProduct({
      ...newProduct,
      [e.target.name]: e.target.value,
    });
  };

  const handleImagePH = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setImagePH(e.target.value);
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    if (e.target.value === "default") {
      setNewProduct({
        ...newProduct,
        [e.target.name]: 0,
      });
    } else {
      setNewProduct({
        ...newProduct,
        [e.target.name]: Number(e.target.value),
      });
    }
  };

  const handleImage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setNewProduct({
      ...newProduct,
      images: [
        ...newProduct.images,
        imagePH
      ],
    });
    setImagePH(imgDefault);
    const selector = document.getElementById("image") as HTMLSelectElement;
    selector.value = "";
  };

  const handleDeleteImg = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const newImages = newProduct.images.filter(
      (el) => el !== e.currentTarget.value
    );
    setNewProduct({
      ...newProduct,
      images: newImages,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setNewProduct({
        code: "",
        name: "",
        images: [],
        description: "",
        recommended: false,
        stock: true,
        brandId: 0,
        categoryId: 0,
        subcategoryId: 0,
      });
      const selector = document.getElementById("brandSel") as HTMLSelectElement;
      selector.value = "default";
      const selector2 = document.getElementById(
        "categorySel"
      ) as HTMLSelectElement;
      selector2.value = "default";
      const selector3 = document.getElementById(
        "subcategorySel"
      ) as HTMLSelectElement;
      selector3.value = "default";
    }
  }, [isSuccess]);

  return (
    <div className="option-container create-product">
      {brandsError || categoryError || subcategoryError ? (
        // {false ? (
        <>
          <ul
            className={brandsError ? "errors-container" : "success-container"}
          >
            <li>
              Para crear un producto primero debe existir al menos una marca
            </li>
          </ul>

          <ul
            className={categoryError ? "errors-container" : "success-container"}
          >
            <li>
              Para crear un producto primero debe existir al menos una categoría
            </li>
          </ul>

          <ul
            className={
              subcategoryError ? "errors-container" : "success-container"
            }
          >
            <li>
              Para crear un producto primero debe existir al menos una
              subcategoría
            </li>
          </ul>
        </>
      ) : (
        <form className="create-form">
          <div className="input-container-small">
            <label htmlFor="name">Nombre</label>
            <input
              name="name"
              type="text"
              value={newProduct.name}
              className="input-small"
              onChange={handleForm}
            />
          </div>
          <div className="input-container-small">
            <label htmlFor="code">Código</label>
            <input
              name="code"
              type="text"
              value={newProduct.code}
              className="input-small"
              onChange={handleForm}
            />
          </div>
          <div className="input-container-small">
            <label htmlFor="images">Imagenes</label>
            <div className="image-input-container">
              <input
                id="image"
                name="images"
                type="text"
                className="input-small"
                placeholder="ID de Google Drive..."
                onChange={handleImagePH}
              />
              <button onClick={handleImage} className="save-button">
                Agregar
              </button>
            </div>
            <div className="imgCardContainer">
              {newProduct.images.map((img, i) => {
                return (
                  <article className="imgCard">
                    <img className="imgItem" src={DRIVE_URL + img} alt="Inválida" />
                    <button
                      className="imgButton"
                      value={img}
                      onClick={handleDeleteImg}
                    >
                      x
                    </button>
                  </article>
                );
              })}
            </div>
            <div
              className="img-create-placeholder"
              style={{
                backgroundImage: `url(${DRIVE_URL + imagePH})`,
              }}
            ></div>
          </div>
          <section className="input-container-small">
            <div className="input-container-small description">
              <label htmlFor="description">Descripción</label>
              <textarea
                name="description"
                value={newProduct.description}
                className="input-small description"
                onChange={handleForm}
              />
            </div>
            <div className="select-container-large">
              <label htmlFor="brandId">Marca</label>
              <select
                name="brandId"
                id="brandSel"
                defaultValue={"default"}
                className="select-small"
                onChange={handleSelect}
              >
                <option value={"default"} disabled>
                  Marcas...
                </option>
                {Array.isArray(brands) &&
                  brands?.map((brand) => {
                    return (
                      <option id={brand.name} value={brand.id}>
                        {brand.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="select-container-large">
              <label htmlFor="categoryId">Categoría</label>
              <select
                name="categoryId"
                id="categorySel"
                defaultValue={"default"}
                className="select-small"
                onChange={handleSelect}
              >
                <option value={"default"} disabled>
                  Categoría...
                </option>
                {Array.isArray(categories) &&
                  categories?.map((category) => {
                    return (
                      <option id={category.name} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="select-container-large">
              <label htmlFor="subcategoryId">Subcategoría</label>
              <select
                name="subcategoryId"
                id="subcategorySel"
                defaultValue={"default"}
                className="select-small"
                onChange={handleSelect}
              >
                <option value={"default"} disabled>
                  Subcategorias...
                </option>
                {Array.isArray(subcategories) &&
                  subcategories?.map((subcategory) => {
                    return (
                      <option id={subcategory.name} value={subcategory.id}>
                        {subcategory.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="save-button-container">
              {isLoading && <Spinner size="2rem" opacity={1} />}
              <button onClick={handleCreate} className="save-button">
                {isLoading ? "Enviando..." : "Guardar"}
              </button>
            </div>
          </section>
          {(isError || isSuccess) && (
            <ul className={isError ? "errors-container" : "success-container"}>
              {isError && <li>{errorMsg}</li>}
              {isSuccess && (
                <li>El producto fue creado y asociado correctamente</li>
              )}
            </ul>
          )}
        </form>
      )}
    </div>
  );
};

export default CreateProduct;

import React, { useState } from "react";
import "../../../styles/admin/cards.scss";
import { formatDate } from "../../../utils";
import { DRIVE_URL } from "../../../private/private";

const BrandAdminCard = ({ props }: { props: any }) => {
  const { image, name, updatedAt, createdAt } = props;

  const updateFormat = formatDate(updatedAt);
  const createFormat = formatDate(createdAt);

  const [viewMore, setViewMore] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setViewMore(!viewMore);
  };

  if (props) {
    return (
      <div className="admin-card brand">
        <div className="basicData">
          <div className="description">
            <img className="productImage" src={DRIVE_URL + image} alt={name} />
          </div>
          <div className="description">
            <label className="title">Nombre:</label>
            <p className="text">{name}</p>
          </div>
          <button className="viewMore" onClick={handleClick}>
            {viewMore ? "VER MENOS" : "VER MÁS"}
          </button>
        </div>
        <div className={`moreData ${!viewMore ? "hidden" : "active"}`}>
          <div className="data">
            <div className="description">
              <label className="title">Creado:</label>
              <p className="text">
                {createFormat.date} | {createFormat.time}
              </p>
            </div>
            <div className="description">
              <label className="title">Actualizado:</label>
              <p className="text">
                {updateFormat.date} | {updateFormat.time}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

export default BrandAdminCard;

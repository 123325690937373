import React, { useState } from "react";
import PopUp from "../../popup/PopUp";
import { DRIVE_URL } from "../../../private/private";

const RecoverAdminCard = ({
  element,
  recoverFn,
  deleteFn,
}: {
  element: any;
  recoverFn: Function;
  deleteFn: Function;
}) => {
  const { images, image, name, code, id } = element;
  const [openPopUp, setOpen] = useState(false);

  const handleRecover = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    recoverFn(id);
  };

  const handleDeleteBTN = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <div className="admin-card delete">
      <div className="basicData">
        {images && <img className="productImage" src={DRIVE_URL + images[0]} alt={name} />}
        {image && <img className="productImage" src={DRIVE_URL + image} alt={name} />}
        <div className="description product-name">
          <label className="title">Nombre{code ? "/Código" : ""}:</label>
          <p className="text">
            {name}
            {code ? ` / ${code}` : ""}
          </p>
        </div>
        <div className="buttons-container">
          <button onClick={handleRecover} className="button-recover">
            Recuperar
          </button>
          <button onClick={handleDeleteBTN} className="button-delete">
            Eliminar definitivamente
          </button>
        </div>
        {openPopUp && (
          <PopUp
            title="¿Desea eliminar de forma permanente?"
            text="Esta acción no puede ser revertida, y en caso de ser una marca o categoría esta acción continuará con la eliminación de todos los productos asociados."
            cancel={() => {
              setOpen(false);
            }}
            acept={() => {
              deleteFn(id);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default RecoverAdminCard;

import React, { useState } from "react";
import "../../../../styles/admin/options.scss";
import { useGetProductsQuery } from "../../../../features/productApiSlice";
import Spinner from "../../../spinner/Spinner";
import ProductAdminCard from "../../card/ProductAdminCard";
import { useError } from "../../../../hooks/useError";
import { SearchProps } from "../../../../interfaces/interfaces";
import { useGetBrandsQuery } from "../../../../features/brandApiSlice";
import { useGetCategoriesQuery } from "../../../../features/categoryApiSlice";
import { useGetSubcategoriesQuery } from "../../../../features/subcategoryApiSlice";

const AllProducts = () => {
  //FILTERS | START |
  const [operation, setOperation] = useState("all");
  const [filterRecommended, setRecommended] = useState<string | undefined>(
    undefined
  );
  const [filterBrand, setBrandFilter] = useState<string | undefined>(undefined);
  const [filterCategory, setCategoryFilter] = useState<string | undefined>(
    undefined
  );
  const [filterSubcategory, setSubcategoryFilter] = useState<
    string | undefined
  >(undefined);
  const [filterStock, setStockFilter] = useState<string | undefined>(undefined);
  //FILTERS | END |

  //INFO DE LOS SELECTORES DE FILTROS | START |
  const { data: brands, isLoading: brandsLoading } = useGetBrandsQuery({
    operation: "active",
  });
  const { data: categories, isLoading: categoriesLoading } =
    useGetCategoriesQuery({
      operation: "active",
    });
  const { data: subcategories, isLoading: subcategoriesLoading } =
    useGetSubcategoriesQuery({
      operation: "active",
      filterId: filterCategory,
    });
  //INFO DE LOS SELECTORES DE FILTROS | END |

  const { data, isLoading, isError, error } = useGetProductsQuery({
    operation,
    filterRecommended,
    filterBrand,
    filterCategory,
    filterSubcategory,
    filterStock,
  });

  const [search, setSearch] = useState<SearchProps>({
    active: false,
    content: "",
  });

  const { errorMsg } = useError(error, isError);

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    type: string
  ) => {
    e.preventDefault();
    if (type === "operation") setOperation(e.target.value);
    if (type === "brand")
      setBrandFilter(e.target.value === "all" ? undefined : e.target.value);
    if (type === "category")
      setCategoryFilter(e.target.value === "all" ? undefined : e.target.value);
    if (type === "subcategory")
      setSubcategoryFilter(
        e.target.value === "all" ? undefined : e.target.value
      );
    if (type === "stock")
      setStockFilter(e.target.value === "all" ? undefined : e.target.value);
    if (type === "recommended")
      setRecommended(e.target.value === "all" ? undefined : e.target.value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      active: e.target.value !== "" ? true : false,
      content: e.target.value,
    });
  };

  return (
    <div id="all-container" className="option-container">
      <div className="filter-container">
        <label className="filter-title">Filtrar por:</label>
        <div className="filter-navbar">
          <div className="filter-option">
            <label className="select-name">Disponibilidad:</label>
            <select onChange={(e) => handleSelectChange(e, "operation")}>
              <option id="all" value="all" selected>
                Todos
              </option>
              <option id="active" value="active">
                Activos
              </option>
              <option id="inactive" value="inactive">
                Inactivos
              </option>
            </select>
          </div>
          <div className="filter-option">
            <label className="select-name">Stock:</label>
            <select onChange={(e) => handleSelectChange(e, "stock")}>
              <option id="all" value="all" selected>
                Todos
              </option>
              <option id="true" value="true">
                SI
              </option>
              <option id="false" value="false">
                NO
              </option>
            </select>
          </div>
          <div className="filter-option">
            <label className="select-name">Recomendado:</label>
            <select onChange={(e) => handleSelectChange(e, "recommended")}>
              <option id="all" value="all" selected>
                Todos
              </option>
              <option id="true" value="true">
                SI
              </option>
              <option id="false" value="false">
                NO
              </option>
            </select>
          </div>
          <div>
            {brandsLoading ? (
              <Spinner size="2rem" opacity={0.6} />
            ) : (
              <div className="filter-option">
                <label className="select-name">Marca:</label>
                <select onChange={(e) => handleSelectChange(e, "brand")}>
                  <option value="all" selected>
                    Todas
                  </option>
                  {Array.isArray(brands) &&
                    brands?.map((brand) => {
                      return (
                        <option key={brand.id} value={brand.id}>
                          {brand.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            )}
          </div>
          <div>
            {categoriesLoading ? (
              <Spinner size="2rem" opacity={0.6} />
            ) : (
              <div className="filter-option">
                <label className="select-name">Categoría:</label>
                <select onChange={(e) => handleSelectChange(e, "category")}>
                  <option value="all" selected>
                    Todas
                  </option>
                  {Array.isArray(categories) &&
                    categories?.map((category) => {
                      return (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            )}
          </div>
          <div>
            {subcategoriesLoading ? (
              <Spinner size="2rem" opacity={0.6} />
            ) : (
              <div className="filter-option">
                <label className="select-name">Subcategoría:</label>
                <select onChange={(e) => handleSelectChange(e, "subcategory")}>
                  <option value="all" selected>
                    Todas
                  </option>
                  {Array.isArray(subcategories) &&
                    subcategories?.map((subcategory) => {
                      return (
                        <option key={subcategory.id} value={subcategory.id}>
                          {subcategory.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            )}
          </div>
          <div>
            <div className="search-container">
              <div className="search-bar">
                <input
                  type="text"
                  onChange={handleChange}
                  placeholder="Buscar un producto"
                  value={search.content}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Spinner size="2rem" opacity={0.6} />}
      {isError && <p>{errorMsg}</p>}
      {!isError &&
        Array.isArray(data) &&
        (search.content !== ""
          ? data
              ?.filter((prod) =>
                prod.name.toLowerCase().includes(search.content.toLowerCase())
              )
              .map((el) => {
                return <ProductAdminCard props={el} key={el.id} />;
              })
          : data?.map((el) => {
              return <ProductAdminCard props={el} key={el.id} />;
            }))}
    </div>
  );
};

export default AllProducts;

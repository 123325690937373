import {
  AllQuery,
  CreateProductProps,
  ProductProps,
  UpdateProductProps,
} from "../interfaces/interfaces";
import { apiSlice } from "./apiSlice";

export const productApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<ProductProps[] | ProductProps, AllQuery>({
      query: ({
        operation,
        filterId,
        filterRecommended,
        filterBrand,
        filterCategory,
        filterSubcategory,
        filterStock,
        orderBy
      }) =>
        `/products?operation=${operation}${filterId ? `&id=${filterId}` : ""}${
          filterRecommended ? `&recommended=${filterRecommended}` : ""
        }${filterBrand ? `&brand=${filterBrand}` : ""}${
          filterCategory ? `&category=${filterCategory}` : ""
        }${filterSubcategory ? `&subcategory=${filterSubcategory}` : ""}${
          filterStock ? `&stock=${filterStock}` : ""
        }${orderBy ? '&orderBy=DESC' : ""}`,
      providesTags: ["Products"],
    }),
    createProduct: builder.mutation<string, CreateProductProps>({
      query: (body) => ({
        url: `/products/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Products"],
    }),
    updateProduct: builder.mutation<string, UpdateProductProps>({
      query: ({ id, ...body }) => ({
        url: `/products/update/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Products"],
    }),
    logicStatusProduct: builder.mutation<string, number>({
      query: (id) => ({
        url: `/products/des-active/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Products"],
    }),
    deleteProduct: builder.mutation<string, number>({
      query: (id) => ({
        url: `/products/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Products"],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useLogicStatusProductMutation,
  useDeleteProductMutation,
} = productApiSlice;

import React, { useEffect, useState } from "react";
import "../../../styles/admin/login.scss";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { USER, PASSWORD, LOCAL_KEY } from "../../../private/private";
import logo from "../../../img/DinamoLogo.png";
import { useLocation } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [form, setForm] = useState({
    user: "",
    password: "",
  });
  const logged = window.localStorage.getItem(LOCAL_KEY);

  useEffect(() => {
    if (logged && pathname === "/admin") navigate("/admin/productos");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!form.user || !form.password) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Debe rellenar todos los campos",
      });
    } else {
      if (form.user === USER && form.password === PASSWORD) {
        window.localStorage.setItem(LOCAL_KEY!, USER!);
        navigate("/admin/productos");
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "El usuario y la contraseña no coinciden",
        });
      }
    }
  };

  const handdleForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="loginDiv">
      <img className="logo" src={logo} alt="DinamoLogo"></img>
      <form onSubmit={handleSubmit} className="loginForm" autoComplete="off">
        <input
          type="password"
          name="user"
          value={form.user}
          placeholder="Ingrese su Usuario..."
          className="inputForm"
          autoComplete="on"
          onChange={handdleForm}
        />
        <input
          type="password"
          name="password"
          value={form.password}
          placeholder="Ingrese su Contraseña..."
          className="inputForm"
          autoComplete="on"
          onChange={handdleForm}
        />
        <button type="submit" className="buttonForm">
          Ingresar
        </button>
      </form>
      {/* <div className={s.textDiv}>
                    <p>Para ingresar a la sección de descargas debe introducir el usuario y contraseña. En caso de no tener, puede ponerse en contacto con nosotros por los siguientes medios:</p>
                    <p>Tel/Wpp: (+54) 11-6101-9848 | E-mail: <a href="mailto:herramientasrr@outlook.com">herramientasrr@outlook.com</a></p>
                </div> */}
    </div>
  );
}

export default Login;

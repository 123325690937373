import React from "react";
import "../../styles/main/about.scss";
import wppLogo from "../../img/whatsappLogo.png";
import calendario from "../../img/calendario.png";
import clientes from "../../img/clientes.png";
import marcas from "../../img/marcas.png";
import satisfaccion from "../../img/satisfaccion.png";
import { DRIVE_URL } from "../../private/private";

const AboutLayout = () => {
  return (
    <div className="aboutContainer">
      <img
        src={`${DRIVE_URL}1mGWAomJber2Xp6i6qfI3IgU7iBJHn4_c`}
        alt="banner"
        width={"100%"}
        className="aboutBanner"
      />
      <section className="aboutInfo">
        <div id="whoWeAre">
          <article>
            <section className="iconAboutContainer">
              <img
                src={calendario}
                alt="calendario"
                className="iconAbout calendar"
              />
            </section>
            <p>
              Dinamo es una empresa con casi 10 años de trayectoria en el
              mercado eléctrico en los cuales forjamos lazos firmes con nuestros
              clientes y proveedores
            </p>
          </article>
          <article>
            <section className="iconAboutContainer">
              <img src={clientes} alt="clientes" className="iconAbout" />
            </section>
            <p>
              Nuestra misión principal es brindar un soporte técnico y
              asesoramiento de primera mano a nuestros clientes para entender
              sus necesidades.
            </p>
          </article>
          <article>
            <section className="iconAboutContainer">
              <img src={marcas} alt="marcas" className="iconAbout" />
            </section>
            <p>Somos distribuidores de las principales marcas del mercado.</p>
          </article>
          <article>
            <section className="iconAboutContainer">
              <img
                src={satisfaccion}
                alt="satisfaccion"
                className="iconAbout"
              />
            </section>
            <p>
              Nuestro principal objetivo es brindar el mejor servicio, precio y
              calidad a nuestros clientes.
            </p>
          </article>
        </div>
        <div id="location">
          <h2>¿Dónde nos encontrás?</h2>
          <div className="locationContainer">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d104906.81391176795!2d-58.455201423633554!3d-34.74707918030818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcd3209dacec35%3A0x2bbe0d0f373e0395!2sMATERIALES%20ELECTRICOS%20DINAMO%20ZONA%20SUR!5e0!3m2!1ses!2sar!4v1700390547707!5m2!1ses!2sar"
              width="100%"
              height="350"
              frameBorder={0}
              title="ubicacion"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />

            <section className="data">
              <article>
                <h3 className="title">Dirección:</h3>
                <p>Av. Almte. Brown 3795, B1834 Temperley</p>
                <p>Provincia de Buenos Aires</p>
              </article>
              <article>
                <h3 className="title">Horarios de atencion:</h3>
                <p>Lunes a Viernes de 8:00 a 18:00</p>
                <p>Sábados de 9:00 a 13:00</p>
              </article>
              <a
                href="https://wa.me//5401126065540?text=Hola!,%20Quiero%20hacer%20una%20consulta%20sobre...%20"
                target="_blank"
                rel="noreferrer"
                className="buttonLink"
              >
                <img src={wppLogo} alt="wpp" />
                CHATEA CON NOSOTROS
              </a>
            </section>
          </div>
        </div>
        <div id="gallery">
          <section className="galleryTitle">
            <h2>Galeria de imagenes</h2>
            <a
              href="https://drive.google.com/drive/folders/1Z5OMYF7LP-VItp4dnhgPacPuEguYg_TD?usp=drive_link"
              target="_blank"
              rel="noreferrer"
            >
              Ver todas las imagenes
            </a>
          </section>
          <section className="imgContainer">
            <img
              src={`${DRIVE_URL}1fO0RTFIfFt7wvHqIQPqXxrjy4IZ6S6ZP`}
              alt="driveImg"
              className="driveImg"
            />
            <img
              src={`${DRIVE_URL}1779cXE7Cx4_EbVKfVwcOrzS9vmdWDDMe`}
              alt="driveImg"
              className="driveImg"
            />
            <img
              src={`${DRIVE_URL}1f6FEpSUEDuou7hZtmPBuGnju2dYsENja`}
              alt="driveImg"
              className="driveImg"
            />
            <img
              src={`${DRIVE_URL}1g2VTxGPMaZ2h0WrcPRwfeuwBQssVk9Zu`}
              alt="driveImg"
              className="driveImg"
            />
          </section>
        </div>
      </section>
    </div>
  );
};

export default AboutLayout;

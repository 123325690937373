import React from "react";
// import { useLocation, useNavigate } from "react-router-dom";
import "../styles/main/main.scss";
import NavBar from "../components/main/header/NavBar";
import Content from "../components/main/content/Content";
import TopBar from "../components/main/header/TopBar";
import Footer from "../components/main/footer/Footer";

function Main() {
  // const location = useLocation();
  // const navigate = useNavigate();

  return (
    <div className="mainDiv">
      <TopBar />
      <NavBar />
      <Content />
      <Footer />
    </div>
  );
}

export default Main;

import React from "react";
import "../../../styles/main/header.scss";
import logo from "../../../img/DinamoLogo.png";
import logoName from "../../../img/DinamoName.png";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const handleNavigate = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    navigate("/");
  };
  return (
    <div id="headerMain">
      <div className="headerLogo" onClick={handleNavigate}>
        <img src={logo} alt="ImagenLogo" className="logo" />
        <img src={logoName} alt="NombreLogo" className="name" />
      </div>
    </div>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import { useCreateSubcategoryMutation } from "../../../../features/subcategoryApiSlice";
import { useGetCategoriesQuery } from "../../../../features/categoryApiSlice";
import { useError } from "../../../../hooks/useError";
import Spinner from "../../../spinner/Spinner";

const CreateSubcategory = () => {
  const [newSubcategory, setNewSubcategory] = useState({
    name: "",
    categoryId: 0,
  });
  const [createSubcategory, response] = useCreateSubcategoryMutation();
  const { data: categories } = useGetCategoriesQuery({ operation: "active" });
  const { isLoading, isError, error, isSuccess } = response;
  const { errorMsg } = useError(error, isError);

  const handleForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setNewSubcategory({
      ...newSubcategory,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setNewSubcategory({
      ...newSubcategory,
      [e.target.name]: Number(e.target.value),
    });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    createSubcategory(newSubcategory);
  };

  useEffect(() => {
    if (isSuccess) {
      setNewSubcategory({ name: "", categoryId: 0 });
      const selector = document.getElementById(
        "categorySelect"
      ) as HTMLSelectElement;
      selector.value = "default";
    }
  }, [isSuccess]);

  return (
    <div className="option-container create-subcategory">
      {Array.isArray(categories) && !categories?.length ? (
        <ul className="errors-container">
          <li>
            Para crear una subcategoría primero debe existir al menos una
            categoría
          </li>
        </ul>
      ) : (
        <form className="create-form">
          <div className="input-container-small">
            <label htmlFor="name">Nombre</label>
            <input
              name="name"
              type="text"
              value={newSubcategory.name}
              className="input-small"
              onChange={handleForm}
            />
          </div>
          <div className="select-container-large">
            <label htmlFor="categoryId">Categoría</label>
            <select
              name="categoryId"
              id="categorySelect"
              defaultValue={"default"}
              className="select-small"
              onChange={handleSelect}
            >
              <option value={"default"} disabled>
                Categoría...
              </option>
              {Array.isArray(categories) &&
                categories?.map((category) => {
                  return (
                    <option
                      key={category.id}
                      id={category.name}
                      value={category.id}
                    >
                      {category.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="save-button-container">
            {isLoading && <Spinner size="2rem" opacity={1} />}
            <button onClick={handleSubmit} className="save-button">
              {isLoading ? "Enviando..." : "Guardar"}
            </button>
          </div>
          {(isError || isSuccess) && (
            <ul className={isError ? "errors-container" : "success-container"}>
              {isError && <li>{errorMsg}</li>}
              {isSuccess && (
                <li>La subategoría fue creada y asociada correctamente</li>
              )}
            </ul>
          )}
        </form>
      )}
    </div>
  );
};

export default CreateSubcategory;

import { useEffect } from "react";
import "../../styles/admin/admin-main.scss";
import { optionsButtons } from "../../private/private";
import ButtonOption from "./options/ButtonOption";
import { MenuOptionProps } from "../../interfaces/interfaces";

const MenuOptions = (props: MenuOptionProps) => {
  const { activate, optionActive } = props;

  useEffect(() => {
    const button = document.querySelector(".button-option.active");
    const buttonActive = document.querySelector(
      `#button-option${optionActive}`
    );
    if (button) {
      button.className = button.className.replace(" active", "");
    }
    if (buttonActive) {
      buttonActive.className += " active";
    }
  }, [optionActive]);

  return (
    <div className="menu-options">
      {optionsButtons.map(({ id, name }) => {
        return (
          <ButtonOption label={name} key={id} id={id} activate={activate} />
        );
      })}
    </div>
  );
};

export default MenuOptions;

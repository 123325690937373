import React, { useState } from "react";
import { ProductProps } from "../../../interfaces/interfaces";
import Slider from "react-slick";
import { productImgSettings } from "../../../features/sliderConfig";
import defaultImg from "../../../img/imgDefault.jpeg";
import "../../../styles/main/card.scss";
import { Link } from "react-router-dom";
import { DRIVE_URL } from "../../../private/private";

const MainCard = (props: ProductProps) => {
  const { name, brand, description, recommended, stock, images, id } = props;
  const [active, setActive] = useState<boolean>(false);

  const handleActive = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setActive(!active);
  };
  return (
    <div
      className={`mainProductCard${stock ? "" : " unavailable"}${
        recommended ? " recommended" : ""
      }`}
    >
      <section className="nameSection">
        <h3 className="cardTitle">{name}</h3>
        <p>{brand.name}</p>
      </section>
      <section className="productImages">
        <Slider {...productImgSettings}>
          {images?.map((img, i) => {
            return <img src={DRIVE_URL + img} alt={name} key={i} className="cardImg" />;
          })}
          {!images.length && (
            <img src={defaultImg} alt={name} className="cardImg" />
          )}
        </Slider>
        {recommended && <div className="recommendedTag">RECOMENDADO</div>}
      </section>
      <section className="productButtons">
        <button
          type="button"
          className="cardDescriptionWrapper"
          disabled={!stock}
          onClick={handleActive}
        >
          Ver descripción
        </button>
        <Link to={`/producto/${id}`} className="pdpLink">
          Ver Producto
        </Link>
      </section>
      <div className={`cardDescription${active ? " active" : ""}`}>
        <p>{description}</p>
        <button
          type="button"
          className="cardDescriptionWrapper"
          onClick={handleActive}
        >
          Ocultar descripción
        </button>
      </div>
      {!stock && (
        <div className="unavailableBackdrop">
          <p className="unavailableText">SIN STOCK</p>
        </div>
      )}
    </div>
  );
};

export default MainCard;

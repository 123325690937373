import React, { useEffect, useState } from "react";
import { useCreateCategoryMutation } from "../../../../features/categoryApiSlice";
import { useError } from "../../../../hooks/useError";
import Spinner from "../../../spinner/Spinner";

const CreateCategory = () => {
  const [newCategory, setNewCategory] = useState({
    name: "",
  });
  const [createCategory, response] = useCreateCategoryMutation();
  const { isSuccess, isError, error, isLoading } = response;
  const { errorMsg } = useError(error, isError);

  const handdleForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setNewCategory({ name: e.target.value });
  };

  useEffect(() => {
    if (isSuccess) {
      setNewCategory({ name: "" });
    }
  }, [isSuccess]);

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await createCategory(newCategory);
    if (response.isSuccess) {
      setNewCategory({ name: "" });
    }
  };

  return (
    <div className="option-container create-category">
      <form className="create-form">
        <div className="input-container-small">
          <label htmlFor="name">Nombre</label>
          <input
            name="name"
            type="text"
            value={newCategory.name}
            className="input-small"
            onChange={handdleForm}
          />
        </div>
        <div className="save-button-container">
          {isLoading && <Spinner size="2rem" opacity={1} />}
          <button onClick={handleSubmit} className="save-button">
            {isLoading ? "Enviando..." : "Guardar"}
          </button>
        </div>
      </form>
      {(isError || isSuccess) && (
        <ul className={isError ? "errors-container" : "success-container"}>
          {isError && <li>{errorMsg}</li>}
          {isSuccess && (
            <li>La categoría fue creada y asociada correctamente</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default CreateCategory;

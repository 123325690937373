import {
  SubCategoryProps,
  AllQuery,
  SubcategoryPost,
  UpdateSubcategoryProps,
} from "../interfaces/interfaces";
import { apiSlice } from "./apiSlice";

export const subcategoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubcategories: builder.query<
      SubCategoryProps[] | SubCategoryProps,
      AllQuery
    >({
      query: ({ operation, filterId, filterSubcategory }) =>
        `/subcategories?operation=${operation}${
          filterId ? `&categoryId=${filterId}` : ""
        }${filterSubcategory ? `&subcategoryName=${filterSubcategory}` : ""}`,
      providesTags: ["Subcategories"],
    }),
    createSubcategory: builder.mutation<SubCategoryProps, SubcategoryPost>({
      query: (body) => ({
        url: `/subcategories/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Subcategories"],
    }),
    updateSubcategory: builder.mutation<
      SubCategoryProps,
      UpdateSubcategoryProps
    >({
      query: ({ id, ...body }) => ({
        url: `/subcategories/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Subcategories"],
    }),
    logicStatusSubcategory: builder.mutation<string, number>({
      query: (id) => ({
        url: `/subcategories/des-active/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Subcategories"],
    }),
    deleteSubcategory: builder.mutation<string, number>({
      query: (id) => ({
        url: `/subcategories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Subcategories"],
    }),
  }),
});

export const {
  useCreateSubcategoryMutation,
  useGetSubcategoriesQuery,
  useUpdateSubcategoryMutation,
  useLogicStatusSubcategoryMutation,
  useDeleteSubcategoryMutation,
} = subcategoryApiSlice;

import React from "react";

const ScrollUp = () => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };
  return (
    <div style={{ position: "fixed", bottom: "1rem", right: "1rem" }}>
      <button onClick={handleClick} className="scrollUp">
        Subir
      </button>
    </div>
  );
};

export default ScrollUp;

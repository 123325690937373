import React, { useState } from "react";
import "../../../styles/admin/cards.scss";
import { formatDate } from "../../../utils";

const SubcategoryAdminCard = ({ props }: { props: any }) => {
  const { name, updatedAt, createdAt, category } = props;

  const updateFormat = formatDate(updatedAt);
  const createFormat = formatDate(createdAt);

  const [viewMore, setViewMore] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setViewMore(!viewMore);
  };

  if (props) {
    return (
      <div className="admin-card subcategory">
        <div className="basicData">
          <div className="description">
            <label className="title">Nombre:</label>
            <p className="text">{name}</p>
          </div>
          <div className="description">
            <label className="title">Categoría:</label>
            <p className="text">{category.name}</p>
          </div>
          <button className="viewMore" onClick={handleClick}>
            {viewMore ? "VER MENOS" : "VER MÁS"}
          </button>
        </div>
        <div className={`moreData ${!viewMore ? "hidden" : "active"}`}>
          <div className="data">
            <div className="description">
              <label className="title">Creado:</label>
              <p className="text">
                {createFormat.date} | {createFormat.time}
              </p>
            </div>
            <div className="description">
              <label className="title">Actualizado:</label>
              <p className="text">
                {updateFormat.date} | {updateFormat.time}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

export default SubcategoryAdminCard;

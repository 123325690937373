import { useState } from "react";
import "../../../../styles/admin/options.scss";
import Spinner from "../../../spinner/Spinner";
import { useError } from "../../../../hooks/useError";
import DeleteAdminCard from "../../card/DeleteAdminCard";
import {
  useGetBrandsQuery,
  useLogicStatusBrandMutation,
} from "../../../../features/brandApiSlice";
import { SearchProps } from "../../../../interfaces/interfaces";

const DeleteBrand = () => {
  const [search, setSearch] = useState<SearchProps>({
    active: false,
    content: "",
  });
  const {
    data: brands,
    isLoading,
    isError,
    error,
  } = useGetBrandsQuery({
    operation: "active",
  });
  const { errorMsg } = useError(error, isError);
  const [logicStatusBrand, response] = useLogicStatusBrandMutation();
  const { isSuccess, error: delError, isError: delIsError } = response;
  const { errorMsg: deleteError } = useError(delError, delIsError);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      active: e.target.value !== "" ? true : false,
      content: e.target.value,
    });
  };

  return (
    <div className="option-container delete">
      {isLoading && <Spinner size="2rem" opacity={1} />}
      {!isError && !isLoading && (
        <div className="search-container">
          <div className="search-bar">
            <input
              type="text"
              onChange={handleChange}
              placeholder="Buscar una marca"
              value={search.content}
            ></input>
          </div>
        </div>
      )}
      {(isError || delIsError) && (
        <ul className="errors-container">
          {isError && <li>{errorMsg}</li>}
          {delIsError && <li>{deleteError}</li>}
        </ul>
      )}
      {isSuccess && (
        <ul className="success-container">
          {isSuccess && <li>{response.data}</li>}
        </ul>
      )}
      {!isError &&
        Array.isArray(brands) &&
        (search.active
          ? brands
              .filter((brand) =>
                brand?.name
                  ?.toLowerCase()
                  .includes(search.content.toLowerCase())
              )
              .map((brand) => {
                return (
                  <DeleteAdminCard
                    deleteFn={logicStatusBrand}
                    element={brand}
                  />
                );
              })
          : brands.map((brand) => {
              return (
                <DeleteAdminCard deleteFn={logicStatusBrand} element={brand} />
              );
            }))}
    </div>
  );
};

export default DeleteBrand;

import React from "react";

const DeleteAdminCard = ({
  element,
  deleteFn,
}: {
  element: any;
  deleteFn: Function;
}) => {
  const { images, image, name, code, id } = element;

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    deleteFn(id);
  };

  return (
    <div className="admin-card delete">
      <div className="basicData">
        {images && <img className="productImage" src={images[0]} alt={name} />}
        {image && <img className="productImage" src={image} alt={name} />}
        <div className="description product-name">
          <label className="title">Nombre{code ? "/Código" : ""}:</label>
          <p className="text">
            {name}
            {code ? ` / ${code}` : ""}
          </p>
        </div>
        <button onClick={handleDelete} className="button-delete">
          Eliminar
        </button>
      </div>
    </div>
  );
};

export default DeleteAdminCard;

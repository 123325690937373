import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import s from "./App.module.css";
import Admin from "../../pages/admin/AdminPage";
import Login from "../admin/login/LoginComponent";
import Main from "../../pages/MainView.page";
import List from "../../pages/list/ListPage";
import About from "../../pages/about/About";
import ProductPage from "../../pages/PDP/Product";

function App() {
  useEffect(() => {
    return localStorage.removeItem("userRR");
  }, []);

  return (
    <div className={s.divContainer}>
      <Routes>
        <Route path="/admin" element={<Login />} />
        <Route path="/admin/*" caseSensitive element={<Admin />} />
        <Route path="/" element={<Main />} />
        <Route path="/listados/*" element={<List />} />
        <Route path="/producto/:id" element={<ProductPage />} />
        <Route path="/nosotros" element={<About />} />
      </Routes>
    </div>
  );
}

export default App;

import { useEffect, useState } from "react";
import "../../../../styles/admin/options.scss";
import Spinner from "../../../spinner/Spinner";
import {
  SearchProps,
  UpdateCategoryProps,
} from "../../../../interfaces/interfaces";
import {
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
} from "../../../../features/categoryApiSlice";
import { useError } from "../../../../hooks/useError";

const EditCategory = () => {
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const { data: categories, isLoading } = useGetCategoriesQuery({
    operation: "active",
    filterCategory: selected,
  });
  const [search, setSearch] = useState<SearchProps>({
    active: false,
    content: "",
  });
  const [updateCategory, response] = useUpdateCategoryMutation();
  const { isSuccess, error, isError } = response;
  const { errorMsg } = useError(error, isError);
  const [editCategory, setEditCategory] = useState<UpdateCategoryProps>({
    id: 0,
    name: "",
    isActive: true,
  });

  useEffect(() => {
    if (!Array.isArray(categories) && categories) {
      const { updatedAt, createdAt, ...formated } = categories;
      setEditCategory(formated);
    }
  }, [categories]);

  //HANDLES START
  const handleUpdate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    updateCategory(editCategory);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      active: e.target.value !== "" ? true : false,
      content: e.target.value,
    });
  };
  const handleSelectItem = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSearch({
      active: false,
      content: "",
    });
    setSelected(e.currentTarget.value);
  };
  const handleForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setEditCategory({
      ...editCategory,
      [e.target.name]: e.target.value,
    });
  };
  //HANDLES END

  return (
    <div className="option-container edit-category">
      {Array.isArray(categories) && (
        <>
          <div className="search-container">
            <h5 className="search-name">Seleccionar una categoría:</h5>
            <div className="search-bar">
              <input
                type="text"
                onChange={handleChange}
                placeholder="Buscar una categoría"
                value={search.content}
              ></input>
            </div>
            <div className="search-results" hidden={!search.active}>
              {search.content !== "" &&
                categories
                  .filter((category) =>
                    category?.name
                      ?.toLowerCase()
                      .includes(search.content.toLowerCase())
                  )
                  .map((category) => {
                    return (
                      <button
                        className="result"
                        key={category.id}
                        onClick={handleSelectItem}
                        value={category.name}
                      >
                        {category.name}
                      </button>
                    );
                  })}
            </div>
          </div>
          {categories.map((category) => {
            return (
              <button
                className="result"
                key={category.id}
                onClick={handleSelectItem}
                value={category.name}
              >
                {category.name}
              </button>
            );
          })}
        </>
      )}
      {isLoading && (
        <div className="spinner-row">
          <Spinner size="2rem" opacity={1} />
          <p>Cargando información de la/s categoría/s...</p>
        </div>
      )}
      {selected !== undefined && !Array.isArray(categories) && (
        <>
          <div className="selected-container">
            <p className="selected-text">
              Articulo seleccionado: <span>{categories?.name}</span>
            </p>
            <button
              onClick={handleSelectItem}
              value={undefined}
              className="change-button"
            >
              Cambiar
            </button>
          </div>
          <form className="create-form">
            <div className="input-container-small">
              <label htmlFor="name">Nombre</label>
              <input
                name="name"
                type="text"
                value={editCategory.name}
                className="input-small"
                onChange={handleForm}
              />
              <div className="save-button-container">
                {isLoading && <Spinner size="2rem" opacity={1} />}
                <button
                  disabled={editCategory.name === ""}
                  onClick={handleUpdate}
                  className="save-button"
                >
                  {isLoading ? "Enviando..." : "Guardar"}
                </button>
              </div>
              {(isError || isSuccess || isError) && (
                <ul
                  className={isError ? "errors-container" : "success-container"}
                >
                  {isError && <li>{errorMsg}</li>}
                  {isSuccess && (
                    <li>La categoría fue actualizada correctamente</li>
                  )}
                </ul>
              )}
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default EditCategory;

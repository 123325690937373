import { ButtonAdminProps } from "../../../interfaces/interfaces";
import { Link } from "react-router-dom";

const ButtonAdmin = (props: ButtonAdminProps) => {
  const { path, first, id, last, activate } = props;

  return (
    <div>
      <Link
        to={`/admin/${path}`}
        relative="path"
        id={`menu-link${id}`}
        className={`menu-link${first ? " first" : ""}${last ? " last" : ""}`}
        onClick={() => {
          activate(id);
        }}
      >
        {path}
      </Link>
    </div>
  );
};

export default ButtonAdmin;

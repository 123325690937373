import { useState } from "react";
import "../../../../styles/admin/options.scss";
import {
  useGetProductsQuery,
  useLogicStatusProductMutation,
} from "../../../../features/productApiSlice";
import Spinner from "../../../spinner/Spinner";
import { useError } from "../../../../hooks/useError";
import DeleteAdminCard from "../../card/DeleteAdminCard";
import { SearchProps } from "../../../../interfaces/interfaces";

const DeleteProduct = () => {
  const [search, setSearch] = useState<SearchProps>({
    active: false,
    content: "",
  });
  const {
    data: products,
    isLoading,
    isError,
    error,
  } = useGetProductsQuery({
    operation: "active",
  });
  const { errorMsg } = useError(error, isError);
  const [logicStatusProduct, response] = useLogicStatusProductMutation();
  const { isSuccess, error: delError, isError: delIsError } = response;
  const { errorMsg: deleteError } = useError(delError, delIsError);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      active: e.target.value !== "" ? true : false,
      content: e.target.value,
    });
  };

  return (
    <div className="option-container delete-product">
      {isLoading && <Spinner size="2rem" opacity={1} />}
      {!isError && !isLoading && (
        <div className="search-container">
          <div className="search-bar">
            <input
              type="text"
              onChange={handleChange}
              placeholder="Buscar un producto"
              value={search.content}
            ></input>
          </div>
        </div>
      )}
      {(isError || delIsError) && (
        <ul className="errors-container">
          {isError && <li>{errorMsg}</li>}
          {delIsError && <li>{deleteError}</li>}
        </ul>
      )}
      {isSuccess && (
        <ul className="success-container">
          {isSuccess && <li>{response.data}</li>}
        </ul>
      )}
      {!isError &&
        Array.isArray(products) &&
        (search.active
          ? products
              .filter((prod) =>
                prod.name.toLowerCase().includes(search.content.toLowerCase())
              )
              .map((prod) => {
                return (
                  <DeleteAdminCard
                    deleteFn={logicStatusProduct}
                    element={prod}
                  />
                );
              })
          : products.map((prod) => {
              return (
                <DeleteAdminCard deleteFn={logicStatusProduct} element={prod} />
              );
            }))}
    </div>
  );
};

export default DeleteProduct;

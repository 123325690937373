import React from "react";
import "../../styles/main/list.scss";
import TopBar from "../../components/main/header/TopBar";
import NavBar from "../../components/main/header/NavBar";
import Footer from "../../components/main/footer/Footer";
// import { useGetBrandsQuery } from "../../features/brandApiSlice";
import ListLayout from "./Layout";

const List = () => {
  return (
    <div className="listContainer">
      <TopBar />
      <NavBar />
      <ListLayout />
      <Footer />
    </div>
  );
};

export default List;

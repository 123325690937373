import React, { useState } from "react";
import "../../../../styles/admin/options.scss";
import { useGetBrandsQuery } from "../../../../features/brandApiSlice";
import Spinner from "../../../spinner/Spinner";
import BrandAdminCard from "../../card/BrandAdminCard";
import { useError } from "../../../../hooks/useError";

const AllBrands = () => {
  const [operation, setOperation] = useState("all");

  const { data, isLoading, isError, error } = useGetBrandsQuery({
    operation,
  });
  const { errorMsg } = useError(error, isError);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setOperation(e.target.value);
  };

  return (
    <div className="option-container">
      <div className="filter-container">
        <label className="filter-title">Filtrar por:</label>
        <div className="filter-navbar">
          <div>
            <label className="select-name">Disponibilidad:</label>
            <select defaultValue={"all"} onChange={handleSelectChange}>
              <option id="all" value="all">
                Todos
              </option>
              <option id="active" value="active">
                Activos
              </option>
              <option id="incative" value="inactive">
                Inactivos
              </option>
            </select>
          </div>
        </div>
      </div>
      {operation === "inactive" && (
        <ul className="alert-container">
          <li>Si desea restaurar estos elementos, debera ir a la papelera</li>
        </ul>
      )}
      {isLoading && <Spinner size="2rem" opacity={0.6} />}
      {isError && <p>{errorMsg}</p>}
      {Array.isArray(data) &&
        data?.map((el) => {
          return <BrandAdminCard props={el} key={el.id} />;
        })}
    </div>
  );
};

export default AllBrands;

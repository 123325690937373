import { useState } from "react";
import "../../../../styles/admin/options.scss";
import Spinner from "../../../spinner/Spinner";
import { useError } from "../../../../hooks/useError";
import RecoverAdminCard from "../../card/RecoverAdminCard";
import {
  useDeleteBrandMutation,
  useGetBrandsQuery,
  useLogicStatusBrandMutation,
} from "../../../../features/brandApiSlice";
import { SearchProps } from "../../../../interfaces/interfaces";

const TrashBrand = () => {
  const [search, setSearch] = useState<SearchProps>({
    active: false,
    content: "",
  });
  const {
    data: brands,
    isLoading,
    isError,
    error,
  } = useGetBrandsQuery({
    operation: "inactive",
  });
  const { errorMsg } = useError(error, isError);

  const [logicStatusBrand, response] = useLogicStatusBrandMutation();
  const { isSuccess, error: delError, isError: delIsError } = response;
  const { errorMsg: deleteError } = useError(delError, delIsError);

  const [deleteBrand, status] = useDeleteBrandMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      active: e.target.value !== "" ? true : false,
      content: e.target.value,
    });
  };

  return (
    <div className="option-container delete brand">
      {isLoading && <Spinner size="2rem" opacity={1} />}
      {!isError && !isLoading && (
        <div className="search-container">
          <div className="search-bar">
            <input
              type="text"
              onChange={handleChange}
              placeholder="Buscar una marca"
              value={search.content}
            ></input>
          </div>
        </div>
      )}
      {(isError || delIsError) && (
        <ul className="errors-container">
          {isError && <li>{errorMsg}</li>}
          {delIsError && <li>{deleteError}</li>}
        </ul>
      )}
      {isSuccess && (
        <ul className="success-container">
          <li>{response.data}</li>
        </ul>
      )}
      {status.isSuccess && (
        <ul className="success-container">
          <li>
            La marca ha sido eliminada completamente de la base de datos junto a
            los productos que tenía asociados.
          </li>
        </ul>
      )}
      {!isError &&
        Array.isArray(brands) &&
        (search.active
          ? brands
              .filter((prod) =>
                prod?.name?.toLowerCase().includes(search.content.toLowerCase())
              )
              .map((prod) => {
                return (
                  <RecoverAdminCard
                    recoverFn={logicStatusBrand}
                    deleteFn={deleteBrand}
                    element={prod}
                  />
                );
              })
          : brands.map((prod) => {
              return (
                <RecoverAdminCard
                  recoverFn={logicStatusBrand}
                  deleteFn={deleteBrand}
                  element={prod}
                />
              );
            }))}
    </div>
  );
};

export default TrashBrand;

import {
  AllQuery,
  CategoryPost,
  CategoryProps,
  UpdateCategoryProps,
} from "../interfaces/interfaces";
import { apiSlice } from "./apiSlice";

export const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<CategoryProps[] | CategoryProps, AllQuery>({
      query: ({ operation, filterSubcategory, filterCategory }) =>
        `/categories?operation=${operation}${
          filterSubcategory ? `&subcategory=${filterSubcategory}` : ""
        }${filterCategory ? `&categoryCategory=${filterCategory}` : ""}`,
      providesTags: ["Categories"],
    }),
    createCategory: builder.mutation<CategoryProps, CategoryPost>({
      query: (body) => ({
        url: `/categories/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Categories"],
    }),
    updateCategory: builder.mutation<CategoryProps, UpdateCategoryProps>({
      query: ({ id, ...body }) => ({
        url: `/categories/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Categories"],
    }),
    logicStatusCategory: builder.mutation<string, number>({
      query: (id) => ({
        url: `/categories/des-active/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Categories"],
    }),
    deleteCategory: builder.mutation<string, number>({
      query: (id) => ({
        url: `/categories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Categories"],
    }),
  }),
});

export const {
  useCreateCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
  useLogicStatusCategoryMutation,
  useDeleteCategoryMutation,
} = categoryApiSlice;

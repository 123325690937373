import React from "react";
import "../../../styles/main/topBar.scss";
import { topBarSettings } from "../../../features/sliderConfig";
import Slider from "react-slick";

const TopBar = () => {
  return (
    <div>
      <Slider {...topBarSettings}>
        <p className="topBarText">Realizá tu pedido por mail o whatsapp</p>
        <p className="topBarText">
          ¡Envíos disponibles! Consultanos por tu zona
        </p>
      </Slider>
    </div>
  );
};

export default TopBar;

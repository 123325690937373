import React from "react";
import Search from "./Search";
import "../../../styles/main/navBar.scss";
import Menu from "./Menu";
import Header from "./Header";

function NavBar() {
  return (
    <div className="navBar">
      <Menu />
      <Header />
      <Search />
    </div>
  );
}

export default NavBar;

import React, { useEffect, useState } from "react";
import { useCreateBrandMutation } from "../../../../features/brandApiSlice";
import imgDefault from "../../../../img/imgDefault.jpeg";
import { useError } from "../../../../hooks/useError";
import Spinner from "../../../spinner/Spinner";
import { DRIVE_URL } from "../../../../private/private";

const CreateBrand = () => {
  const [newBrand, setNewBrand] = useState({
    name: "",
    image: imgDefault,
  });
  const [createBrand, response] = useCreateBrandMutation();
  const { isSuccess, isError, isLoading, error } = response;
  const { errorMsg } = useError(error, isError);

  const handdleForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.name === "image") {
      setNewBrand({
        ...newBrand,
        image: e.target.value,
      });
    } else {
      setNewBrand({
        ...newBrand,
        name: e.target.value,
      });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    createBrand(newBrand);
  };

  useEffect(() => {
    if (isSuccess) {
      setNewBrand({
        name: "",
        image: imgDefault,
      });
      const input = document.querySelector("#imageInput") as HTMLInputElement;
      input.value = "";
    }
  }, [isSuccess]);

  return (
    <div className="option-container create-brand">
      <form className="create-form">
        <div className="input-container-small">
          <label htmlFor="image">Imagen</label>
          <input
            id="imageInput"
            name="image"
            type="text"
            className="input-small"
            onChange={handdleForm}
          />
          <div
            className="img-create-placeholder"
            style={{
              backgroundImage: `url(${DRIVE_URL + newBrand.image})`,
            }}
          ></div>
        </div>
        <div className="input-container-small">
          <label htmlFor="name">Nombre</label>
          <input
            name="name"
            type="text"
            value={newBrand.name}
            className="input-small"
            onChange={handdleForm}
          />
          <div className="save-button-container">
            {isLoading && <Spinner size="2rem" opacity={1} />}
            <button onClick={handleSubmit} className="save-button">
              {isLoading ? "Enviando..." : "Guardar"}
            </button>
          </div>
          {(isError || isSuccess) && (
            <ul className={isError ? "errors-container" : "success-container"}>
              {isError && <li>{errorMsg}</li>}
              {isSuccess && <li>La marca fue creada correctamente</li>}
            </ul>
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateBrand;

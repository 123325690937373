import React, { useEffect, useState } from "react";
import "../../styles/main/list.scss";
import { useGetBrandsQuery } from "../../features/brandApiSlice";
import { useGetCategoriesQuery } from "../../features/categoryApiSlice";
import { useGetSubcategoriesQuery } from "../../features/subcategoryApiSlice";
import { FiltersNames } from "./Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

interface FilterProps {
  setBrand: Function;
  setCategory: Function;
  setSubcategory: Function;
  setFilters: Function;
  setRecommended: Function;
  setStock: Function;
}
const options = [
  { value: "all", label: "Todos" },
  { value: "true", label: "Si" },
  { value: "false", label: "No" },
];

const Filters = (props: FilterProps) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const {
    setBrand,
    setCategory,
    setFilters,
    setSubcategory,
    setRecommended,
    setStock,
  } = props;
  const [currentCategory, setCurrentCategory] = useState<string | undefined>(
    undefined
  );
  const { data: brands } = useGetBrandsQuery({
    operation: "active",
    filterCategory: currentCategory,
  });
  const { data: categories } = useGetCategoriesQuery({
    operation: "active",
  });
  const { data: subcategories } = useGetSubcategoriesQuery({
    operation: "active",
    filterId: currentCategory,
  });

  const [brandDropdown, setBrandDropdown] = useState<boolean>(false);
  const [categoryDropdown, setCategoryDropdown] = useState<boolean>(false);
  const [subcategoryDropdown, setSubcategoryDropdown] =
    useState<boolean>(false);
  const [recommendedDropdown, setRecommendedDropdown] =
    useState<boolean>(false);
  const [stockDropdown, setStockDropdown] = useState<boolean>(false);

  const handleChangeFilters = (
    e: React.MouseEvent<HTMLDivElement>,
    id: number,
    name?: string
  ) => {
    e.preventDefault();
    setFilters((prevState: FiltersNames) => {
      return {
        ...prevState,
        brand: name,
      };
    });
    setBrand(String(id));
    
  };

  const clearFilters = () => {
    setBrand(undefined);
    setCategory(undefined);
    setFilters(undefined);
    setSubcategory(undefined);
    setRecommended(undefined);
    setStock(undefined);
    setCurrentCategory(undefined)
  };

  useEffect(() => {
    const path = pathname.split('/')
    const isMenuNav = path.length > 2
    const catNav = path.at(2) 
    const subcatNav = path.at(3) 
    // console.log({path, isMenuNav, catNav, subcatNav})
    if(isMenuNav) {
      clearFilters();
      if (catNav) {
        const findedCat = Array.isArray(categories) && categories?.find(el => el.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") === catNav.replace(/%20/g, " "))
        console.log(catNav)
        if(findedCat) {
          setFilters((prevState: FiltersNames) => {
            return {
              ...prevState,
              category: catNav.replace(/%20/g, " "),
            };
          });
          setCategory(String(findedCat.id));
          setCurrentCategory(String(findedCat.id));
        }
        if(subcatNav) {
          const findedSub = findedCat && findedCat.subcategories.find(el => el.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") === subcatNav.replace(/%20/g, " "))
          console.log({subcatNav, findedSub})
          if(findedSub) {
            setFilters((prevState: FiltersNames) => {
              return {
                ...prevState,
                subcategory: subcatNav.replace(/%20/g, " "),
              };
            });
            setSubcategory(String(findedSub.id));
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, subcategories, pathname])
  return (
    <div className="filters">
      <h3>Filtrar por:</h3>
      <section className="filtersSection brands">
        <div
          className="dropDown"
          onClick={(e) => {
            e.preventDefault();
            setBrandDropdown(!brandDropdown);
          }}
        >
          Marca
        </div>
        <div
          className={`dropDownOption ${
            brandDropdown ? "dropDown_Visible" : "dropDown_Hidden"
          }`}
        >
          {Array.isArray(brands) &&
            brands.map((brand) => {
              return (
                <div
                  className="option"
                  key={brand.id}
                  onClick={(e) =>
                    handleChangeFilters(e, brand.id, brand.name)
                  }
                >
                  {brand.name}
                </div>
              );
            })}
        </div>
      </section>
      <section className="filtersSection categories">
        <div
          className="dropDown"
          onClick={(e) => {
            e.preventDefault();
            setCategoryDropdown(!categoryDropdown);
          }}
        >
          Categoría
        </div>
        <div
          className={`dropDownOption ${
            categoryDropdown ? "dropDown_Visible" : "dropDown_Hidden"
          }`}
        >
          {Array.isArray(categories) &&
            categories.map((category) => {
              return (
                <Link
                  className={`option${String(category.id) === currentCategory ? " selected" : ""}`}
                  to={`/listados/${category.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`}
                  key={category.id}
                >
                  {category.name}
                </Link>
              );
            })}
        </div>
      </section>
      {currentCategory && <section className="filtersSection subcategories">
        <div
          className="dropDown"
          onClick={(e) => {
            e.preventDefault();
            setSubcategoryDropdown(!subcategoryDropdown);
          }}
        >
          Subcategoría
        </div>
        <div
          className={`dropDownOption ${
            subcategoryDropdown ? "dropDown_Visible" : "dropDown_Hidden"
          }`}
        >
          {Array.isArray(subcategories) &&
            subcategories.map((subcategory) => {
              return (
                <Link
                  to={`/listados/${subcategory.category.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}/${subcategory.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`}
                  className="option"
                  key={subcategory.id}
                >
                  {subcategory.name}
                </Link>
              );
            })}
        </div>
      </section>}
      <section className="filtersSection recommended">
        <div
          className="dropDown"
          onClick={(e) => {
            e.preventDefault();
            setRecommendedDropdown(!recommendedDropdown);
          }}
        >
          Recomendado
        </div>
        <div
          className={`dropDownOption ${
            recommendedDropdown ? "dropDown_Visible" : "dropDown_Hidden"
          }`}
        >
          {options.map((el, i) => {
            return (
              <div
                className="option"
                key={i}
                onClick={(e) => {
                  e.preventDefault();
                  setRecommended(el.value === "all" ? undefined : el.value);
                }}
              >
                {el.label}
              </div>
            );
          })}
        </div>
      </section>
      <section className="filtersSection stock">
        <div
          className="dropDown"
          onClick={(e) => {
            e.preventDefault();
            setStockDropdown(!stockDropdown);
          }}
        >
          Stock
        </div>
        <div
          className={`dropDownOption ${
            stockDropdown ? "dropDown_Visible" : "dropDown_Hidden"
          }`}
        >
          {options.map((el, i) => {
            return (
              <div
                className="option"
                key={i}
                onClick={(e) => {
                  e.preventDefault();
                  setStock(el.value === "all" ? undefined : el.value);
                }}
              >
                {el.label}
              </div>
            );
          })}
        </div>
      </section>
      <section className="filtersSection clear">
        <div className="dropDown" onClick={() => {clearFilters(); 
    navigate('/listados')}}>
          Limpiar Filtros
        </div>
      </section>
    </div>
  );
};

export default Filters;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, RAILWAY_URL } from "../private/private";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    // headers.set("Access-Control-Allow-Origin", WEB_URL);
    headers.set("Access-Control-Allow-Origin", RAILWAY_URL);
    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: [
    "Products",
    "ProductSelected",
    "Brands",
    "Categories",
    "Subcategories",
    "ListProducts",
  ],
  endpoints: () => ({}),
});

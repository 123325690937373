import React from "react";
import { useGetProductsQuery } from "../../features/productApiSlice";
import Slider from "react-slick";
import { productImgSettings } from "../../features/sliderConfig";
import defaultImg from "../../img/imgDefault.jpeg";
import wppLogo from "../../img/whatsappLogo.png";
import "../../styles/main/pdp.scss";
import Spinner from "../../components/spinner/Spinner";
import { Link } from "react-router-dom";
import { DRIVE_URL } from "../../private/private";

interface PDPProps {
  id?: string;
}
const PDPLayout = (props: PDPProps) => {
  const { data: product, isLoading } = useGetProductsQuery({
    operation: "all",
    filterId: props.id,
  });

  if (isLoading) {
    return (
      <div className="loading-page">
        <Spinner opacity={1} size="10rem" />
      </div>
    );
  }

  return (
    <div>
      {!Array.isArray(product) && (
        <div className="product-container">
          <section className="left-container">
            <article className="linkContainer">
              <Link to="/listados" className="go-back">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.1456 4.15466C11.876 3.88513 11.4244 3.88513 11.1548 4.15466L4.15484 11.1547C4.09102 11.2185 4.03643 11.2943 4.00338 11.3825C3.97204 11.466 3.9502 11.5522 3.9502 11.65C3.9502 11.7479 3.97204 11.834 4.00338 11.9176C4.03643 12.0057 4.09102 12.0816 4.15484 12.1454L11.1548 19.1454C11.4244 19.4149 11.876 19.4149 12.1456 19.1454C12.4151 18.8758 12.4151 18.4242 12.1456 18.1547L6.34091 12.35H18.6502C19.0378 12.35 19.3502 12.0376 19.3502 11.65C19.3502 11.2624 19.0378 10.95 18.6502 10.95H6.34091L12.1456 5.14537C12.4151 4.87584 12.4151 4.42419 12.1456 4.15466Z"
                    fill="currentColor"
                  />
                </svg>
                <p>Ir a listados</p>
              </Link>
              <Link to="/" className="go-back">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.1456 4.15466C11.876 3.88513 11.4244 3.88513 11.1548 4.15466L4.15484 11.1547C4.09102 11.2185 4.03643 11.2943 4.00338 11.3825C3.97204 11.466 3.9502 11.5522 3.9502 11.65C3.9502 11.7479 3.97204 11.834 4.00338 11.9176C4.03643 12.0057 4.09102 12.0816 4.15484 12.1454L11.1548 19.1454C11.4244 19.4149 11.876 19.4149 12.1456 19.1454C12.4151 18.8758 12.4151 18.4242 12.1456 18.1547L6.34091 12.35H18.6502C19.0378 12.35 19.3502 12.0376 19.3502 11.65C19.3502 11.2624 19.0378 10.95 18.6502 10.95H6.34091L12.1456 5.14537C12.4151 4.87584 12.4151 4.42419 12.1456 4.15466Z"
                    fill="currentColor"
                  />
                </svg>
                <p>Ir a inicio</p>
              </Link>
            </article>
            <Slider>
              <Slider {...productImgSettings}>
                {product?.images?.map((img, i) => {
                  return (
                    <img
                      src={DRIVE_URL + img}
                      alt={product?.name}
                      key={i}
                      className="cardImg"
                    />
                  );
                })}
                {!product?.images.length && (
                  <img
                    src={defaultImg}
                    alt={product?.name}
                    className="cardImg"
                  />
                )}
              </Slider>
            </Slider>
          </section>
          <section className="right-container">
            <h1 className="title">{product?.name}</h1>
            <h3 className="brand">{product?.brand?.name}</h3>
            {!product?.stock && <p className="stock">SIN STOCK</p>}
            {product?.recommended && (
              <p className="recommended">ARTÍCULO RECOMENDADO</p>
            )}
            <p className="pdp-description">{product?.description}</p>
            <a
              href={
                product?.stock
                  ? `https://wa.me//5401126065540?text=Hola!,%20Quiero%20conocer%20el%20precio%20de%20${product?.name},%20link%20de%20referencia:%20${window.location}`
                  : `https://wa.me//5401126065540?text=Hola!,%20Quiero%20saber%20cuando%20ingresa%20el%20producto:%20${product?.name},%20link%20de%20referencia:%20${window.location}`
              }
              target="_blank"
              rel="noreferrer"
              className="buttonLink"
            >
              <img src={wppLogo} alt="wpp" />
              {product?.stock
                ? "CONSULTÁ EL PRECIO"
                : "CONSULTÁ POR DISPONIBILIDAD"}
            </a>
          </section>
        </div>
      )}
    </div>
  );
};

export default PDPLayout;

import React from "react";
import "../../styles/main/list.scss";

interface PaginationProps {
    currentPage?: number,
    changePrevNextPage: React.MouseEventHandler<HTMLDivElement>,
    changePage: Function,
    pages: string[],
    totalPages?: number
}

const Pagination = (props: PaginationProps) => {
    const {currentPage, changePrevNextPage, changePage, pages, totalPages} = props

    return (
        <div className="pagination">
              <div
                role="button"
                id="prev"
                className={`prevButton${
                  currentPage === 1 ? " disabled" : ""
                }`}
                onClick={changePrevNextPage}
              >
                {"<"}
              </div>
              {pages.map((__, i) => {
                const page = i + 1;
                if (totalPages && totalPages > 5) {
                  if (currentPage && currentPage < 4) {
                    if (page > 4 && page < totalPages) {
                      return <></>;
                    } else {
                      return (
                        <div
                          role="button"
                          className={`pageButton${
                            currentPage === page ? " active" : ""
                          }${page === totalPages ? " last" : ""}`}
                          onClick={(e) => changePage(e, page)}
                          key={page}
                        >
                          {page}
                        </div>
                      );
                    }
                  } else {
                    if (currentPage === totalPages) {
                      if (
                        page === 1 ||
                        page === currentPage - 2 ||
                        page === currentPage - 1 ||
                        page === currentPage ||
                        page === totalPages
                      ) {
                        return (
                          <div
                            role="button"
                            className={`pageButton${
                              currentPage === page ? " active" : ""
                            }${page === 1 ? " first" : ""}`}
                            onClick={(e) => changePage(e, page)}
                            key={page}
                          >
                            {page}
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    } else {
                      if (
                        currentPage &&
                        (page === 1 ||
                        page === currentPage - 1 ||
                        page === currentPage ||
                        page === currentPage + 1 ||
                        page === totalPages)
                      ) {
                        return (
                          <div
                            role="button"
                            className={`pageButton${
                              currentPage === page ? " active" : ""
                            }${page === 1 ? " first" : ""}${
                              page === totalPages ? " last" : ""
                            }`}
                            onClick={(e) => changePage(e, page)}
                            key={page}
                          >
                            {page}
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    }
                  }
                } else {
                  return (
                    <div
                      role="button"
                      className={`pageButton${
                        currentPage === page ? " active" : ""
                      }`}
                      onClick={(e) => changePage(e, page)}
                      key={page}
                    >
                      {page}
                    </div>
                  );
                }
              })}
              <div
                role="button"
                id="next"
                className={`nextButton${
                  currentPage === totalPages ? " disabled" : ""
                }`}
                onClick={changePrevNextPage}
              >
                {">"}
              </div>
            </div>
    )
}

export default Pagination;
import React from "react";
import "../../styles/general/popup.scss";

interface PopUpProps {
  title: string;
  text: string;
  aceptTxt?: string;
  acept: Function;
  cancel: Function;
}

const PopUp = (props: PopUpProps) => {
  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.cancel();
  };
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.acept();
  };

  return (
    <div className="popup-backdrop">
      <div className="popup-container">
        <div className="popup-title">{props.title}</div>
        <div className="popup-text">{props.text}</div>
        <div className="buttons-container">
          <button className="cancel" onClick={handleCancel}>
            Cancelar
          </button>
          <button className="delete" onClick={handleDelete}>
            {props.aceptTxt || "Eliminar"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUp;

import React from "react";
import TopBar from "../../components/main/header/TopBar";
import NavBar from "../../components/main/header/NavBar";
import Footer from "../../components/main/footer/Footer";
import PDPLayout from "./Layout";
import { useParams } from "react-router-dom";

const ProductPage = () => {
  const { id } = useParams();

  return (
    <div className="productPageContainer">
      <TopBar />
      <NavBar />
      <PDPLayout id={id} />
      <Footer />
    </div>
  );
};

export default ProductPage;

import React, { useState } from "react";
import "../../../styles/admin/cards.scss";
import { formatDate } from "../../../utils";
import { CategoryProps } from "../../../interfaces/interfaces";

const CategoryAdminCard = ({ category }: { category: CategoryProps }) => {
  const { name, updatedAt, createdAt, subcategories } = category;

  const updateFormat = formatDate(updatedAt);
  const createFormat = formatDate(createdAt);

  const [viewMore, setViewMore] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setViewMore(!viewMore);
  };

  return (
    <div className="admin-card category">
      <div className="basicData">
        <div className="description">
          <label className="title">Nombre:</label>
          <p className="text">{name}</p>
        </div>
        <div className="description">
          <label className="title">Subcategorías:</label>
          <div>
            {subcategories.map(({ name, id }) => {
              return (
                <p key={id} className="text">
                  {name}
                </p>
              );
            })}
          </div>
        </div>
        <button className="viewMore" onClick={handleClick}>
          {viewMore ? "VER MENOS" : "VER MÁS"}
        </button>
      </div>
      <div className={`moreData ${!viewMore ? "hidden" : "active"}`}>
        <div className="data">
          <div className="description">
            <label className="title">Creado:</label>
            <p className="text">
              {createFormat.date} | {createFormat.time}
            </p>
          </div>
          <div className="description">
            <label className="title">Actualizado:</label>
            <p className="text">
              {updateFormat.date} | {updateFormat.time}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryAdminCard;

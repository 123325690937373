import React from "react";
import TopBar from "../../components/main/header/TopBar";
import NavBar from "../../components/main/header/NavBar";
import Footer from "../../components/main/footer/Footer";
import AboutLayout from "./Layout";

const About = () => {
    return (
      <div className="AboutContainer">
        <TopBar />
        <NavBar />
        <AboutLayout />
        <Footer />
      </div>
    );
  };
  
  export default About;
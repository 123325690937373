import React, { useEffect, useState } from "react";
import "../../styles/admin/admin-main.scss";
import HeaderAdmin from "../../components/admin/header/HeaderAdmin";
import MenuOptions from "../../components/admin/MenuOptions";
import AllProducts from "../../components/admin/options/Products/AllProducts";
import AllBrands from "../../components/admin/options/Brands/AllBrands";
import CreateBrand from "../../components/admin/options/Brands/CreateBrand";
import AllCategories from "../../components/admin/options/Categories/AllCategories";
import { useLocation, useNavigate } from "react-router-dom";
import { LOCAL_KEY, adminButtons } from "../../private/private";
import AllSubcategories from "../../components/admin/options/Subcategories/AllSubcategories";
import CreateProduct from "../../components/admin/options/Products/CreateProduct";
import CreateCategory from "../../components/admin/options/Categories/CreateCategory";
import CreateSubcategory from "../../components/admin/options/Subcategories/CreateSubcategory";
import EditProduct from "../../components/admin/options/Products/EditProduct";
import EditBrand from "../../components/admin/options/Brands/EditBrand";
import EditCategory from "../../components/admin/options/Categories/EditCategory";
import EditSubcategory from "../../components/admin/options/Subcategories/EditSubcategory";
import DeleteProduct from "../../components/admin/options/Products/DeleteProduct";
import TrashProduct from "../../components/admin/options/Products/TrashProduct";
import DeleteBrand from "../../components/admin/options/Brands/DeleteBrand";
import TrashBrand from "../../components/admin/options/Brands/TrashBrand";
import DeleteCategory from "../../components/admin/options/Categories/DeleteCategory";
import DeleteSubcategory from "../../components/admin/options/Subcategories/DeleteSubcategory";
import TrashCategory from "../../components/admin/options/Categories/TrashCategory";
import TrashSubcategory from "../../components/admin/options/Subcategories/TrashSubcategory";
import Swal from "sweetalert2";
import ScrollUp from "../../components/scrollUp/scrollUp";

const Admin = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [activeOption, setActiveOption] = useState(1);
  const selected = adminButtons.find(
    (el) => el.name === pathname.split("/")[2]
  )?.id;
  const logged = window.localStorage.getItem(LOCAL_KEY);

  useEffect(() => {
    if (!logged && pathname !== "/admin") {
      navigate("/admin");
      Swal.fire({
        icon: "error",
        text: "Debes iniciar sesión para ver el contenido",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id="admin">
        <HeaderAdmin selected={selected || 1} />
        <div className="admin-content">
          <MenuOptions optionActive={activeOption} activate={setActiveOption} />

          {activeOption === 1 ? (
            selected === 1 ? (
              <AllProducts />
            ) : selected === 2 ? (
              <AllBrands />
            ) : selected === 3 ? (
              <AllCategories />
            ) : (
              selected === 4 && <AllSubcategories />
            )
          ) : (
            <></>
          )}

          {activeOption === 2 ? (
            selected === 1 ? (
              <CreateProduct />
            ) : selected === 2 ? (
              <CreateBrand />
            ) : selected === 3 ? (
              <CreateCategory />
            ) : (
              selected === 4 && <CreateSubcategory />
            )
          ) : (
            <></>
          )}

          {activeOption === 3 ? (
            selected === 1 ? (
              <EditProduct />
            ) : selected === 2 ? (
              <EditBrand />
            ) : selected === 3 ? (
              <EditCategory />
            ) : (
              selected === 4 && <EditSubcategory />
            )
          ) : (
            <></>
          )}

          {activeOption === 4 ? (
            selected === 1 ? (
              <DeleteProduct />
            ) : selected === 2 ? (
              <DeleteBrand />
            ) : selected === 3 ? (
              <DeleteCategory />
            ) : (
              selected === 4 && <DeleteSubcategory />
            )
          ) : (
            <></>
          )}

          {activeOption === 5 ? (
            selected === 1 ? (
              <TrashProduct />
            ) : selected === 2 ? (
              <TrashBrand />
            ) : selected === 3 ? (
              <TrashCategory />
            ) : (
              selected === 4 && <TrashSubcategory />
            )
          ) : (
            <></>
          )}
        </div>
        <ScrollUp />
      </div>
    </>
  );
};

export default Admin;

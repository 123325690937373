import { useEffect, useState } from "react";
import "../../../../styles/admin/options.scss";
import {
  useGetProductsQuery,
  useUpdateProductMutation,
} from "../../../../features/productApiSlice";
import Spinner from "../../../spinner/Spinner";
import {
  SearchProps,
  UpdateProductProps,
} from "../../../../interfaces/interfaces";
import imgDefault from "../../../../img/imgDefault.jpeg";
import { useGetBrandsQuery } from "../../../../features/brandApiSlice";
import { useGetCategoriesQuery } from "../../../../features/categoryApiSlice";
import { useGetSubcategoriesQuery } from "../../../../features/subcategoryApiSlice";
import { useError } from "../../../../hooks/useError";
import { DRIVE_URL } from "../../../../private/private";

const EditProduct = () => {
  const { data: brands } = useGetBrandsQuery({
    operation: "active",
  });
  const { data: categories } = useGetCategoriesQuery({
    operation: "active",
  });
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const [imagePH, setImagePH] = useState<string>(imgDefault);
  const [search, setSearch] = useState<SearchProps>({
    active: false,
    content: "",
  });
  const {
    data: products,
    isLoading,
    isError,
    error,
  } = useGetProductsQuery({
    operation: "active",
    filterId: selected,
  });
  const { errorMsg } = useError(error, isError);
  const [updateProduct, response] = useUpdateProductMutation();
  const {
    isSuccess,
    error: updtError,
    isError: updtIsError,
    data: updateMsg,
  } = response;
  const { errorMsg: updateError } = useError(updtError, updtIsError);
  const [editProduct, setEditProduct] = useState<UpdateProductProps>({
    id: 0,
    code: "",
    name: "",
    images: [],
    description: "",
    recommended: false,
    stock: true,
    brandId: 0,
    categoryId: 0,
    subcategoryId: 0,
  });
  const { data: subcategories } = useGetSubcategoriesQuery({
    operation: "active",
    filterId:
      editProduct.categoryId !== 0 ? String(editProduct.categoryId) : undefined,
  });

  useEffect(() => {
    if (!Array.isArray(products) && products) {
      const {
        brand,
        category,
        subcategory,
        updatedAt,
        createdAt,
        ...formated
      } = products;
      setEditProduct(formated);
      const selector = document.getElementById("brandSel") as HTMLSelectElement;
      selector.value = `${products?.brandId}`;
      const selectorStock = document.getElementById(
        "stockSel"
      ) as HTMLSelectElement;
      selectorStock.value = `${products?.stock}`;
      const selectorRmd = document.getElementById(
        "recommendedSel"
      ) as HTMLSelectElement;
      selectorRmd.value = `${products?.recommended}`;
      const selector2 = document.getElementById(
        "categorySel"
      ) as HTMLSelectElement;
      selector2.value = `${products?.categoryId}`;
      const selector3 = document.getElementById(
        "subcategorySel"
      ) as HTMLSelectElement;
      selector3.value = `${products?.subcategoryId}`;
    }
  }, [products]);

  //HANDLES START
  const handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    updateProduct(editProduct);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      active: e.target.value !== "" ? true : false,
      content: e.target.value,
    });
  };
  const handleSelectItem = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSearch({
      active: false,
      content: "",
    });
    setSelected(e.currentTarget.value);
  };
  const handleForm = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    setEditProduct({
      ...editProduct,
      [e.target.name]: e.target.value,
    });
  };
  const handleImagePH = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.value === "") {
      setImagePH(imgDefault);
    } else {
      setImagePH(e.target.value);
    }
  };
  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    if (e.target.value === "default") {
      setEditProduct({
        ...editProduct,
        [e.target.name]: 0,
      });
    } else {
      setEditProduct({
        ...editProduct,
        [e.target.name]: Number(e.target.value),
      });
    }
  };
  const handleImage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setEditProduct({
      ...editProduct,
      images: [
        ...editProduct.images,
        imagePH,
      ],
    });
    setImagePH(imgDefault);
    const selector = document.getElementById("image") as HTMLSelectElement;
    selector.value = "";
  };
  const handleDeleteImg = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const newImages = editProduct.images.filter(
      (el) => el !== e.currentTarget.value
    );
    setEditProduct({
      ...editProduct,
      images: newImages,
    });
  };
  const handleBooleanSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setEditProduct({
      ...editProduct,
      [e.target.name]: e.target.value === "true" ? true : false,
    });
  };
  //HANDLES END

  return (
    <div className="option-container edit-product">
      {isError && (
        <ul className={"errors-container"}>
          <li>{errorMsg}</li>
        </ul>
      )}
      {Array.isArray(products) && (
        <>
          <div className="search-container">
            <h5 className="search-name">Seleccionar un producto:</h5>
            <div className="search-bar">
              <input
                type="text"
                onChange={handleChange}
                placeholder="Buscar un producto"
                value={search.content}
              ></input>
            </div>
            <div className="search-results" hidden={!search.active}>
              {search.content !== "" &&
                products
                  .filter((prod) =>
                    prod.name
                      .toLowerCase()
                      .includes(search.content.toLowerCase())
                  )
                  .map((product, i) => {
                    return (
                      <button
                        className="result"
                        key={product.id}
                        onClick={handleSelectItem}
                        value={product.id}
                      >
                        {product.name}
                      </button>
                    );
                  })}
            </div>
          </div>
          {products.map((product) => {
            return (
              <button
                className="result"
                key={product.id}
                onClick={handleSelectItem}
                value={product.id}
              >
                {product.name}
              </button>
            );
          })}
        </>
      )}
      {isLoading && (
        <div className="spinner-row">
          <Spinner size="2rem" opacity={1} />
          <p>Cargando información del producto...</p>
        </div>
      )}
      {selected !== undefined && !Array.isArray(products) && (
        <>
          <div className="selected-container">
            <p className="selected-text">
              Articulo seleccionado: <span>{editProduct?.name}</span>
            </p>
            <button
              onClick={handleSelectItem}
              value={undefined}
              className="change-button"
            >
              Cambiar
            </button>
          </div>
          <form className="create-form">
            <div className="input-container-small">
              <label htmlFor="name">Nombre</label>
              <input
                name="name"
                type="text"
                value={editProduct.name}
                className="input-small"
                onChange={handleForm}
              />
            </div>
            <div className="input-container-small">
              <label htmlFor="code">Código</label>
              <input
                name="code"
                type="text"
                value={editProduct.code}
                className="input-small"
                onChange={handleForm}
              />
            </div>
            <div className="input-container-small">
              <label htmlFor="images">Imagenes</label>
              <div className="image-input-container">
                <input
                  id="image"
                  name="images"
                  type="text"
                  className="input-small"
                  onChange={handleImagePH}
                />
                <button onClick={handleImage} className="save-button">
                  Agregar
                </button>
              </div>
              <div className="imgCardContainer">
                {editProduct.images.map((img, i) => {
                  return (
                    <article className="imgCard" key={i}>
                      <img className="imgItem" src={DRIVE_URL + img} alt="Inválida" />
                      <button
                        className="imgButton"
                        value={img}
                        onClick={handleDeleteImg}
                      >
                        x
                      </button>
                    </article>
                  );
                })}
              </div>
              <div
                className="img-create-placeholder"
                style={{
                  backgroundImage: `url(${DRIVE_URL + imagePH})`,
                }}
              ></div>
            </div>
            <section className="input-container-small">
              <div className="input-container-small description">
                <label htmlFor="description">Descripción</label>
                <textarea
                  name="description"
                  value={editProduct.description}
                  className="input-small description"
                  onChange={handleForm}
                />
              </div>
              <div className="select-container-large">
                <label htmlFor="brandId">Marca</label>
                <select
                  name="brandId"
                  id="brandSel"
                  defaultValue={"default"}
                  className="select-small"
                  onChange={handleSelect}
                >
                  <option value={"default"} disabled>
                    Marcas...
                  </option>
                  {Array.isArray(brands) &&
                    brands?.map((brand) => {
                      return (
                        <option id={brand.name} value={brand.id} key={brand.id}>
                          {brand.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="select-container-large">
                <label htmlFor="categoryId">Categoría</label>
                <select
                  name="categoryId"
                  id="categorySel"
                  defaultValue={"default"}
                  className="select-small"
                  onChange={handleSelect}
                >
                  <option value={"default"} disabled>
                    Categoría...
                  </option>
                  {Array.isArray(categories) &&
                    categories?.map((category) => {
                      return (
                        <option
                          id={category.name}
                          value={category.id}
                          key={category.id}
                        >
                          {category.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="select-container-large">
                <label htmlFor="subcategoryId">Subcategoría</label>
                <select
                  name="subcategoryId"
                  id="subcategorySel"
                  defaultValue={"default"}
                  className="select-small"
                  onChange={handleSelect}
                >
                  <option value={"default"} disabled>
                    Subcategorias...
                  </option>
                  {Array.isArray(subcategories) &&
                    subcategories?.map((subcategory) => {
                      return (
                        <option
                          id={subcategory.name}
                          value={subcategory.id}
                          key={subcategory.id}
                        >
                          {subcategory.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="select2-container">
                <div className="select-container-small">
                  <label htmlFor="stock">Stock</label>
                  <select
                    name="stock"
                    id="stockSel"
                    className="select-small"
                    onChange={handleBooleanSelect}
                  >
                    <option value="true">SI</option>
                    <option value="false">NO</option>
                  </select>
                </div>
                <div className="select-container-small">
                  <label htmlFor="recommended">Recomendado</label>
                  <select
                    name="recommended"
                    id="recommendedSel"
                    className="select-small"
                    onChange={handleBooleanSelect}
                  >
                    <option value="true">SI</option>
                    <option value="false">NO</option>
                  </select>
                </div>
              </div>
              <div className="save-button-container">
                {isLoading && <Spinner size="2rem" opacity={1} />}
                <button onClick={handleEdit} className="save-button">
                  {isLoading ? "Enviando..." : "Guardar"}
                </button>
              </div>
            </section>
            {(isError || isSuccess || updtIsError) && (
              <ul
                className={isError ? "errors-container" : "success-container"}
              >
                {isError && <li>{errorMsg}</li>}
                {updtIsError && <li>{updateError}</li>}
                {isSuccess && <li>{updateMsg}</li>}
              </ul>
            )}
          </form>
        </>
      )}
    </div>
  );
};

export default EditProduct;

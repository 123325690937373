import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
export const downloaderFunct = (link: string, name: string, type?: string) => {
  const downloader = document.createElement("a");
  downloader.href = link;
  downloader.download = name;
  downloader.target = type === "multiple" ? "_blank" : "_self";
  downloader.rel = "noopener";
  document.body.appendChild(downloader);
  downloader.click();
  document.body.removeChild(downloader);
};

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === "object" &&
    error != null &&
    "message" in error &&
    typeof (error as any).message === "string"
  );
}

export const formatDate = (date: string) => {
  const splitted = date.split("T");
  const formattedDate = splitted[0].split("-").reverse().join("-");
  const actualHour = splitted[1].split(".")[0].split(":");
  const formattedHour = `${Number(actualHour[0]) - 3}:${actualHour[1]}`; //restamos 3 por el uso horario UTC

  return { date: formattedDate, time: formattedHour };
};

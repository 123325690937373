import { AllQuery, ProductListProps } from "../interfaces/interfaces";
import { apiSlice } from "./apiSlice";

export const productApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProductsList: builder.query<ProductListProps, AllQuery>({
      query: ({
        operation,
        filterId,
        filterRecommended,
        filterBrand,
        filterCategory,
        filterSubcategory,
        filterStock,
        pagination,
        orderBy
      }) =>
        `/products?operation=${operation}${filterId ? `&id=${filterId}` : ""}${
          filterRecommended ? `&recommended=${filterRecommended}` : ""
        }${filterBrand ? `&brand=${filterBrand}` : ""}${
          filterCategory ? `&category=${filterCategory}` : ""
        }${filterSubcategory ? `&subcategory=${filterSubcategory}` : ""}${
          filterStock ? `&stock=${filterStock}` : ""
        }${
          pagination
            ? `&currentPage=${pagination.currentPage}&pageSize=${pagination.pageSize}`
            : ""
        }${orderBy ? `&orderBy=${orderBy}` : ""}`,
      providesTags: ["ListProducts"],
    }),
  }),
});

export const { useGetProductsListQuery } = productApiSlice;

import { useState } from "react";
import "../../../../styles/admin/options.scss";
import { useError } from "../../../../hooks/useError";
import DeleteAdminCard from "../../card/DeleteAdminCard";
import {
  useGetCategoriesQuery,
  useLogicStatusCategoryMutation,
} from "../../../../features/categoryApiSlice";
import { SearchProps } from "../../../../interfaces/interfaces";
import Spinner from "../../../spinner/Spinner";

const DeleteCategory = () => {
  const [search, setSearch] = useState<SearchProps>({
    active: false,
    content: "",
  });
  const {
    data: categories,
    isLoading,
    isError,
    error,
  } = useGetCategoriesQuery({
    operation: "active",
  });
  const { errorMsg } = useError(error, isError);
  const [logicStatusCategory, response] = useLogicStatusCategoryMutation();
  const { isSuccess, error: delError, isError: delIsError } = response;
  const { errorMsg: deleteError } = useError(delError, delIsError);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      active: e.target.value !== "" ? true : false,
      content: e.target.value,
    });
  };

  return (
    <div className="option-container delete">
      {isLoading && <Spinner size="2rem" opacity={1} />}
      {!isError && !isLoading && (
        <div className="search-container">
          <div className="search-bar">
            <input
              type="text"
              onChange={handleChange}
              placeholder="Buscar una categoría"
              value={search.content}
            ></input>
          </div>
        </div>
      )}
      {(isError || delIsError) && (
        <ul className="errors-container">
          {isError && <li>{errorMsg}</li>}
          {delIsError && <li>{deleteError}</li>}
        </ul>
      )}
      {isSuccess && (
        <ul className="success-container">
          {isSuccess && <li>{response.data}</li>}
        </ul>
      )}
      {!isError &&
        Array.isArray(categories) &&
        (search.active
          ? categories
              .filter((category) =>
                category?.name
                  ?.toLowerCase()
                  .includes(search.content.toLowerCase())
              )
              .map((category) => {
                return (
                  <DeleteAdminCard
                    deleteFn={logicStatusCategory}
                    element={category}
                  />
                );
              })
          : categories.map((category) => {
              return (
                <DeleteAdminCard
                  deleteFn={logicStatusCategory}
                  element={category}
                />
              );
            }))}
    </div>
  );
};

export default DeleteCategory;

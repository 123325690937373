import React from "react";
import { ButtonOptionProps } from "../../../interfaces/interfaces";

const ButtonOption = (props: ButtonOptionProps) => {
  const { label, id, activate } = props;

  return (
    <>
      <button
        onClick={() => activate(id)}
        id={`button-option${id}`}
        className="button-option"
      >
        {label}
      </button>
    </>
  );
};

export default ButtonOption;

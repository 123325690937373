import React, { useState } from "react";
import "../../../styles/admin/cards.scss";
import { formatDate } from "../../../utils";
import { DRIVE_URL } from "../../../private/private";

const ProductAdminCard = ({ props }: { props: any }) => {
  const {
    images,
    name,
    code,
    brand,
    category,
    subcategory,
    description,
    updatedAt,
    recommended,
    createdAt,
    stock,
  } = props;

  const updateFormat = formatDate(updatedAt);
  const createFormat = formatDate(createdAt);

  const [viewMore, setViewMore] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setViewMore(!viewMore);
  };

  if (props) {
    return (
      <div className="admin-card product">
        <div className="basicData">
          <img className="productImage" src={DRIVE_URL + images[0]} alt={name} />
          <div className="description product-name">
            <label className="title">Nombre/Código:</label>
            <p className="text">
              {name} / {code}
            </p>
          </div>
          <div className="description">
            <label className="title">Marca:</label>
            <p className="text">{brand.name}</p>
          </div>
          <div className="description">
            <label className="title">Categoría:</label>
            <p className="text">{category.name}</p>
          </div>
          <div className="description">
            <label className="title">Subcategoría:</label>
            <p className="text">{subcategory.name}</p>
          </div>
          <button className="viewMore" onClick={handleClick}>
            {viewMore ? "VER MENOS" : "VER MÁS"}
          </button>
        </div>
        <div className={`moreData ${!viewMore ? "hidden" : "active"}`}>
          <div className="data">
            <div className="description max-text">
              <label className="title">Descripción:</label>
              <p className="text">{description}</p>
            </div>
            <div className="description">
              <label className="title">Stock:</label>
              <p className="text">{stock ? "SI" : "NO"}</p>
            </div>
            <div className="description">
              <label className="title">Creado:</label>
              <p className="text">
                {createFormat.date} | {createFormat.time}
              </p>
            </div>
            <div className="description">
              <label className="title">Actualizado:</label>
              <p className="text">
                {updateFormat.date} | {updateFormat.time}
              </p>
            </div>
            <div className="description">
              <label className="title">Recomendado:</label>
              <p className="text">{recommended ? "SI" : "NO"}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

export default ProductAdminCard;

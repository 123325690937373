export const USER = "dinamo";
export const PASSWORD = "ariel2023";
export const LOCAL_KEY = "userDinamo";
export const BASE_URL = "https://dinamo-web.onrender.com";
export const WEB_URL = "http://localhost:3000";
export const RAILWAY_URL = "https://dinamo-ecommerce.netlify.app";
export const DRIVE_URL = "https://lh3.google.com/u/0/d/"

export const adminButtons = [
  {
    id: 1,
    name: "productos",
    path: "products",
  },
  {
    id: 2,
    name: "marcas",
    path: "brands",
  },
  {
    id: 3,
    name: "categorias",
    path: "categories",
  },
  {
    id: 4,
    name: "sub-categorias",
    path: "subcategories",
  },
];

export const optionsButtons = [
  {
    id: 1,
    name: "Lista",
  },
  {
    id: 2,
    name: "Crear",
  },
  {
    id: 3,
    name: "Editar",
  },
  {
    id: 4,
    name: "Eliminar",
  },
  {
    id: 5,
    name: "Papelera",
  },
];

import React from "react";
import "../../../styles/main/footer.scss";
import instagram from "../../../img/instagramLogo.png";
import telephone from "../../../img/telLogo.png";
import mail from "../../../img/mailLogo.png";
import wpp from "../../../img/whatsappLogo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="socialContainer">
        <h3>Redes Sociales:</h3>
        <a
          href="https://www.instagram.com/dinamoelectrico/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="logo" width={16} height={16} /> Instagram
        </a>
      </div>
      <div className="contactContainer">
        <h3>Contacto:</h3>
        <p>
          <img src={telephone} alt="tel" width={16} height={16} /> (011)
          2070-8446
        </p>
        <a
          href="https://chatwith.io/s/64df991f0f0ab"
          target="_blank"
          rel="noreferrer"
        >
          <img src={wpp} alt="wpp" width={16} height={16} /> 11-2606-5540
        </a>
        <a href="mailto:dinamoelectrico@outlook.com">
          <img src={mail} alt="mail" width={16} height={16} />
          {" dinamoelectrico@outlook.com"}
        </a>
      </div>
      <div className="linksContainer">
        <h3>Otros links:</h3>
        <Link to={"/nosotros"}>Nosotros</Link>
        <Link to={"/listados"}>Productos</Link>
      </div>
    </div>
  );
};

export default Footer;

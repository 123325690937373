import { useEffect, useState } from "react";
import "../../../../styles/admin/options.scss";
import Spinner from "../../../spinner/Spinner";
import {
  SearchProps,
  UpdateBrandProps,
} from "../../../../interfaces/interfaces";
import imgDefault from "../../../../img/imgDefault.jpeg";
import {
  useGetBrandsQuery,
  useUpdateBrandMutation,
} from "../../../../features/brandApiSlice";
import { useError } from "../../../../hooks/useError";
import { DRIVE_URL } from "../../../../private/private";

const EditBrand = () => {
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const { data: brands, isLoading } = useGetBrandsQuery({
    operation: "active",
    filterId: selected,
  });
  const [search, setSearch] = useState<SearchProps>({
    active: false,
    content: "",
  });
  const [updateBrand, response] = useUpdateBrandMutation();
  const { isSuccess, error, isError } = response;
  const { errorMsg } = useError(error, isError);
  const [editBrand, setEditBrand] = useState<UpdateBrandProps>({
    id: 0,
    name: "",
    image: imgDefault,
    isActive: true,
  });

  useEffect(() => {
    if (!Array.isArray(brands) && brands) {
      const { updatedAt, createdAt, ...formated } = brands;
      setEditBrand(formated);
    }
  }, [brands]);

  //HANDLES START
  const handleUpdate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    updateBrand(editBrand);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({
      active: e.target.value !== "" ? true : false,
      content: e.target.value,
    });
  };
  const handleSelectItem = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSearch({
      active: false,
      content: "",
    });
    setSelected(e.currentTarget.value);
  };
  const handleForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.name === "image") {
      setEditBrand({
        ...editBrand,
        image: e.target.value,
      });
    } else {
      setEditBrand({
        ...editBrand,
        name: e.target.value,
      });
    }
  };
  //HANDLES END

  return (
    <div className="option-container edit-brand">
      {Array.isArray(brands) && (
        <>
          <div className="search-container">
            <h5 className="search-name">Seleccionar una marca:</h5>
            <div className="search-bar">
              <input
                type="text"
                onChange={handleChange}
                placeholder="Buscar una marca"
                value={search.content}
              ></input>
            </div>
            <div className="search-results" hidden={!search.active}>
              {search.content !== "" &&
                brands
                  .filter((brand) =>
                    brand?.name
                      ?.toLowerCase()
                      .includes(search.content.toLowerCase())
                  )
                  .map((brand) => {
                    return (
                      <button
                        className="result"
                        key={brand.id}
                        onClick={handleSelectItem}
                        value={brand.id}
                      >
                        {brand.name}
                      </button>
                    );
                  })}
            </div>
          </div>
          {brands.map((brand) => {
            return (
              <button
                className="result"
                key={brand.id}
                onClick={handleSelectItem}
                value={brand.id}
              >
                {brand.name}
              </button>
            );
          })}
        </>
      )}
      {isLoading && (
        <div className="spinner-row">
          <Spinner size="2rem" opacity={1} />
          <p>Cargando información de la marca...</p>
        </div>
      )}
      {selected !== undefined && !Array.isArray(brands) && (
        <>
          <div className="selected-container">
            <p className="selected-text">
              Articulo seleccionado: <span>{brands?.name}</span>
            </p>
            <button
              onClick={handleSelectItem}
              value={undefined}
              className="change-button"
            >
              Cambiar
            </button>
          </div>
          <form className="create-form">
            <div className="input-container-small">
              <label htmlFor="image">Imagen</label>
              <div className="image-input-container">
                <input
                  id="image"
                  name="image"
                  type="text"
                  className="input-small"
                  onChange={handleForm}
                />
              </div>
              <div
                className="img-create-placeholder"
                style={{ backgroundImage: `url(${DRIVE_URL + editBrand.image})` }}
              ></div>
            </div>
            <div className="input-container-small">
              <label htmlFor="name">Nombre</label>
              <input
                name="name"
                type="text"
                value={editBrand.name}
                className="input-small"
                onChange={handleForm}
              />
              <div className="save-button-container">
                {isLoading && <Spinner size="2rem" opacity={1} />}
                <button
                  disabled={editBrand.name === "" || editBrand.image === ""}
                  onClick={handleUpdate}
                  className="save-button"
                >
                  {isLoading ? "Enviando..." : "Guardar"}
                </button>
              </div>
              {(isError || isSuccess || isError) && (
                <ul
                  className={isError ? "errors-container" : "success-container"}
                >
                  {isError && <li>{errorMsg}</li>}
                  {isSuccess && (
                    <li>El producto fue actualizado correctamente</li>
                  )}
                </ul>
              )}
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default EditBrand;

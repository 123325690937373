import React, { useState } from "react";
import { ProductProps } from "../../../interfaces/interfaces";
import { useGetProductsQuery } from "../../../features/productApiSlice";
import { Link } from "react-router-dom";

const Search = () => {
  const { data, isLoading } = useGetProductsQuery({
    operation: "active",
  });

  const [search, setSearch] = useState<boolean>(false);
  const [searchResult, setResult] = useState<ProductProps[] | []>([]);

  const handleCloseResult = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setSearch(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.value !== "") {
      setSearch(true);
      if (Array.isArray(data)) {
        setResult(
          data?.filter((products) =>
            products?.name?.toLowerCase().includes(e.target.value.toLowerCase())
          )
        );
      }
    } else {
      setSearch(false);
      setResult([]);
    }
  };
  return (
    <div className="search-container">
      <div
        className="outsiderClick"
        onClick={handleCloseResult}
        hidden={!search}
      />
      <div className="search-bar">
        <input
          type="text"
          onChange={handleChange}
          placeholder="Buscar productos..."
          disabled={isLoading}
        ></input>
      </div>
      <div className="search-results" hidden={!search}>
        {search && searchResult.length > 0 ? (
          searchResult.map((products) => {
            return (
              <Link
                className="result"
                key={products.id}
                to={`/producto/${products.id}`}
              >
                {products.name}
              </Link>
            );
          })
        ) : (
          <p className="result">No hay resultados para la búsqueda</p>
        )}
      </div>
    </div>
  );
};

export default Search;

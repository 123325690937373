import React, { useState } from "react";
import { useGetCategoriesQuery } from "../../../features/categoryApiSlice";
import "../../../styles/main/menu.scss";
import Spinner from "../../spinner/Spinner";
import { Link } from "react-router-dom";

const Menu = () => {
  const { data, isLoading } = useGetCategoriesQuery({
    operation: "active",
  });
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleSubMenuMobileOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("menu Mobile", e.currentTarget.id);
    const submenu = document.querySelector(`.${e.currentTarget.id}`);
    console.log(submenu);
    submenu?.classList.toggle("active");
  };
  const handleSubMenuOpen = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const submenu = document.querySelector(`.${e.currentTarget.id}`);
    submenu?.classList.add("active");
  };
  const handleSubMenuClose = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const submenu = document.querySelector(`.${e.currentTarget.id}`);
    submenu?.classList.remove("active");
  };
  return (
    <div
      className="menuTriggerContainer"
      onClick={() => setOpenMenu(!openMenu)}
    >
      <section className="menuTrigger">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M3 6V7.5H21V6H3ZM3 11.25V12.75H21V11.25H3ZM3 16.5V18H21V16.5H3Z"
            fill="#1A1A1A"
          />
        </svg>
        <p className="menuText">Menú</p>
      </section>
      <div className="backdrop" onClick={() => setOpenMenu(false)}>
        <div className={`menuContainer ${openMenu ? "active" : ""}`}>
          {isLoading && (
            <span className="spinnerContainer">
              <Spinner size={"2rem"} opacity={1} /> Cargando información...
            </span>
          )}
          {Array.isArray(data) &&
            data.map((cat) => {
              if (window.innerWidth > 768) {
                return (
                  <Link
                    className={`categoryButton`}
                    id={cat.name.replace(/ /g, "")}
                    key={cat.id}
                    onMouseOver={handleSubMenuOpen}
                    onMouseOut={handleSubMenuClose}
                    to={`/listados/${cat.name
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")}`}
                  >
                    {cat.name}
                    <div
                      className={`subMenuContainer ${cat.name.replace(
                        / /g,
                        ""
                      )}`}
                    >
                      {cat.subcategories.map((sub) => {
                        return (
                          <Link
                            id={cat.name.replace(/ /g, "")}
                            key={sub.id}
                            onMouseOut={handleSubMenuClose}
                            className="subcategoryButton"
                            to={`/listados/${cat.name
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")}/${sub.name
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")}`}
                          >
                            {sub.name}
                          </Link>
                        );
                      })}
                    </div>
                  </Link>
                );
              } else {
                return (
                  <button
                    className={`categoryButton`}
                    id={cat.name.replace(/ /g, "")}
                    key={cat.id}
                    type="button"
                    onClick={handleSubMenuMobileOpen}
                  >
                    {cat.name}
                    <div
                      className={`subMenuContainer ${cat.name.replace(
                        / /g,
                        ""
                      )}`}
                    >
                      {cat.subcategories.map((sub) => {
                        return (
                          <Link
                            id={cat.name.replace(/ /g, "")}
                            key={sub.id}
                            className="subcategoryButton"
                            to={`/listados/${cat.name
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")}/${sub.name
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")}`}
                          >
                            {sub.name}
                          </Link>
                        );
                      })}
                      <Link
                        id={cat.name.replace(/ /g, "")}
                        className="subcategoryButton"
                        to={`/listados/${cat.name
                          .normalize("NFD")
                          .replace(/[\u0300-\u036f]/g, "")}`}
                      >
                        Ver todo
                      </Link>
                    </div>
                  </button>
                );
              }
            })}
          <Link to={"/listados"} className="categoryButton all">
            Ver todo
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Menu;

import React from "react";
import "../../../styles/main/main.scss";
import banner1 from "../../../img/banner1.jpeg";
import banner2 from "../../../img/banner2.jpeg";
import banner3 from "../../../img/banner3.jpeg";
import banner4 from "../../../img/banner4.jpeg";
import banner5 from "../../../img/banner5.jpeg";
import Slider from "react-slick";
import {
  bannerSettings,
  brandsSettings,
  recommendedSettings,
} from "../../../features/sliderConfig";
import { useGetProductsQuery } from "../../../features/productApiSlice";
import MainCard from "./MainCard";
import { useGetBrandsQuery } from "../../../features/brandApiSlice";
import Spinner from "../../spinner/Spinner";
import { DRIVE_URL } from "../../../private/private";

const Content = () => {
  const { data } = useGetProductsQuery({
    operation: "active",
    filterRecommended: "true",
  });

  const { data: brands } = useGetBrandsQuery({
    operation: "active",
  });

  return (
    <div className="content">
      <Slider {...bannerSettings} className="banner">
        <img src={banner1} alt="banner1" className="banner1" />
        <img src={banner2} alt="banner2" className="banner2" />
        <img src={banner3} alt="banner3" className="banner3" />
        <img src={banner4} alt="banner4" className="banner4" />
        <img src={banner5} alt="banner5" className="banner5" />
      </Slider>
      <section className="listLinkContainer">
        <a href="/listados" className="listLink">
          ¡Conocé todos los productos disponibles!
        </a>
      </section>
      <div className="slidersContainer">
        <div className="recommendedContainer">
          <h2 className="recommendedTitle">Productos recomendados:</h2>
          {Array.isArray(data) && data.length > 0 ? (
            <Slider {...recommendedSettings} className="recommended">
              {data?.map((product) => {
                return <MainCard {...product} key={product.id} />;
              })}
            </Slider>
          ) : (
            <Spinner size="2rem" opacity={1} />
          )}
        </div>
        <div className="brandsContainer">
          <h2 className="brandsTitle">Marcas que trabajamos:</h2>
          {Array.isArray(brands) && brands.length > 0 ? (
            <Slider {...brandsSettings} className="brands">
              {brands
                ?.filter(
                  (brand) =>
                    brand.image !==
                    "/static/media/imgDefault.3c0a422b9d39f22e1be7.jpeg"
                )
                .map((brand) => {
                  return (
                    <img
                      className="brandBanner"
                      src={DRIVE_URL + brand.image}
                      alt={brand.name}
                      key={brand.id}
                    />
                  );
                })}
            </Slider>
          ) : (
            <Spinner size="2rem" opacity={1} />
          )}
        </div>
      </div>
    </div>
  );
  //   return (
  //     <div className="mainContent">
  //       <div className="banner">
  //         <div className="bannerTop">
  //           <img src={Iluminarias} alt="banner1" className="banner1" />
  //           <img src={Seguridad} alt="banner2" className="banner2" />
  //         </div>
  //         <div className="bannerBottom">
  //           <img src={Iluminarias} alt="banner3" className="banner3" />
  //           <img src={Seguridad} alt="banner4" className="banner4" />
  //         </div>
  //       </div>
  //     </div>
  //   );
};

export default Content;

import React, { useState } from "react";
import "../../../../styles/admin/options.scss";
import { useGetCategoriesQuery } from "../../../../features/categoryApiSlice";
import Spinner from "../../../spinner/Spinner";
import CategoryAdminCard from "../../card/CategoryAdminCard";
import { useGetSubcategoriesQuery } from "../../../../features/subcategoryApiSlice";
import { useError } from "../../../../hooks/useError";

const AllCategories = () => {
  const [operation, setOperation] = useState("all");
  const [filterSubcat, setFilterSubcat] = useState<string | undefined>(
    undefined
  );

  const { data: subcategories } = useGetSubcategoriesQuery({
    operation: "active",
  });
  const { data, isLoading, isError, error } = useGetCategoriesQuery({
    operation,
    filterSubcategory: filterSubcat,
  });
  const { errorMsg } = useError(error, isError);

  const handleSelectOperation = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setOperation(e.target.value);
  };
  const handleSelectFilterCat = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    if (e.target.value === "default") {
      setFilterSubcat(undefined);
    } else {
      setFilterSubcat(e.target.value);
    }
  };

  return (
    <div id="all-container" className="option-container">
      <div className="filter-container">
        <label className="filter-title">Filtrar por:</label>
        <div className="filter-navbar">
          <div>
            <label className="select-name">Disponibilidad:</label>
            <select defaultValue={"all"} onChange={handleSelectOperation}>
              <option id="all" value="all">
                Todos
              </option>
              <option id="active" value="active">
                Activos
              </option>
              <option id="incative" value="inactive">
                Inactivos
              </option>
            </select>
          </div>
          <div>
            <label className="select-name">Subcategoría:</label>
            <select defaultValue={"default"} onChange={handleSelectFilterCat}>
              <option value="default">Todas</option>
              {Array.isArray(subcategories) &&
                subcategories?.map(({ id, name }) => {
                  return (
                    <option key={id} id={`${id}`} value={name}>
                      {name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
      {isLoading && <Spinner size="2rem" opacity={0.6} />}
      {isError && <p>{errorMsg}</p>}
      {!isError &&
        Array.isArray(data) &&
        data?.map((el) => {
          return <CategoryAdminCard category={el} key={el.id} />;
        })}
    </div>
  );
};

export default AllCategories;

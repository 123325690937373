import React from "react";
import { SpinnerProps } from "../../interfaces/interfaces";
import logo from "../../img/LogoSolo.png";
import "../../styles/general/spinner.scss";

const Spinner = (props: SpinnerProps) => {
  const { size, opacity } = props;
  return (
    <div className="spinner" style={{ width: size, height: size, opacity }}>
      <img src={logo} alt="spinner" width="100%" />
    </div>
  );
};

export default Spinner;
